<template>
  <!-- 分类顺序表老 -->
  <div id="app" style="background: #f4f4f4">
    <div class="harken-ban seqlist-ban" style="max-height: 200px !important">
      <img src="@/assets/img/leetcode.jpg" />
      <div class="words">
        <h2>Leetcode分类顺序表</h2>
        <span></span>
      </div>
    </div>
    <div class="seqlist">
      <!--      <div class="cson-container">-->
      <!--        <div class="bread"><router-link to="/">主页</router-link> <span>/</span> Leetcode分类顺序表</div>-->
      <!--      </div>-->
      <div class="cson-container clearfix">
        <div class="seqlist-le" style="height: inherit; min-height: 707px">
          <ul class="out">
            <li :class="TabActive == 0 ? 'active' : ''" @click="TabActive = 0">
              介绍
            </li>

            <li :class="firstOpen ? 'open' : ''">
              Leetcode 分类顺序<span class="down-icon"></span>
              <h2 class="zone" @click="first">点击区域</h2>
              <ul class="inner">
                <li
                  :class="TabActive == 11 ? 'active' : ''"
                  @click="TabActive = 11"
                >
                  Array
                </li>
                <li
                  :class="TabActive == 12 ? 'active' : ''"
                  @click="TabActive = 12"
                >
                  String
                </li>
                <li
                  :class="TabActive == 13 ? 'active' : ''"
                  @click="TabActive = 13"
                >
                  Math
                </li>
                <li
                  :class="TabActive == 14 ? 'active' : ''"
                  @click="TabActive = 14"
                >
                  Tree
                </li>
                <li
                  :class="TabActive == 15 ? 'active' : ''"
                  @click="TabActive = 15"
                >
                  Backtracking
                </li>
                <li
                  :class="TabActive == 16 ? 'active' : ''"
                  @click="TabActive = 16"
                >
                  Dynamic Programming
                </li>
                <li
                  :class="TabActive == 17 ? 'active' : ''"
                  @click="TabActive = 17"
                >
                  LinkedList
                </li>
                <li
                  :class="TabActive == 18 ? 'active' : ''"
                  @click="TabActive = 18"
                >
                  Binary Search
                </li>
                <li
                  :class="TabActive == 19 ? 'active' : ''"
                  @click="TabActive = 19"
                >
                  Matrix
                </li>
                <li
                  :class="TabActive == 110 ? 'active' : ''"
                  @click="TabActive = 110"
                >
                  DFS & BFS
                </li>
                <li
                  :class="TabActive == 111 ? 'active' : ''"
                  @click="TabActive = 111"
                >
                  Stack & PriorityQueue
                </li>
                <li
                  :class="TabActive == 112 ? 'active' : ''"
                  @click="TabActive = 112"
                >
                  Bit Manipulation
                </li>
                <li
                  :class="TabActive == 113 ? 'active' : ''"
                  @click="TabActive = 113"
                >
                  Topological Sort
                </li>
                <li
                  :class="TabActive == 114 ? 'active' : ''"
                  @click="TabActive = 114"
                >
                  Random
                </li>
                <li
                  :class="TabActive == 115 ? 'active' : ''"
                  @click="TabActive = 115"
                >
                  Graph
                </li>
                <li
                  :class="TabActive == 116 ? 'active' : ''"
                  @click="TabActive = 116"
                >
                  Union Find
                </li>
                <li
                  :class="TabActive == 117 ? 'active' : ''"
                  @click="TabActive = 117"
                >
                  Trie
                </li>
                <li
                  :class="TabActive == 118 ? 'active' : ''"
                  @click="TabActive = 118"
                >
                  Design
                </li>
              </ul>
            </li>
            <li :class="secondOpen ? 'open' : ''">
              Leetcode 重点250题<span class="down-icon"></span>
              <h2 class="zone" @click="second">点击区域</h2>
              <ul class="inner">
                <li
                  :class="TabActive == 21 ? 'active' : ''"
                  @click="TabActive = 21"
                >
                  划分说明
                </li>
                <li
                  :class="TabActive == 22 ? 'active' : ''"
                  @click="TabActive = 22"
                >
                  重点250题
                </li>
              </ul>
            </li>
            <li :class="thirdOpen ? 'open' : ''">
              Data Science Leetcode精简版<span class="down-icon"></span>
              <h2 class="zone" @click="third">点击区域</h2>
              <ul class="inner">
                <li
                  :class="TabActive == 31 ? 'active' : ''"
                  @click="TabActive = 31"
                >
                  划分说明
                </li>
                <li
                  :class="TabActive == 32 ? 'active' : ''"
                  @click="TabActive = 32"
                >
                  精简题目
                </li>
              </ul>
            </li>
            <!-- <li :class="fourthOpen?'open':''">刷题常见问题<span class="down-icon"></span>
              <h2 class="zone" @click="fourth">点击区域</h2>
              <ul class="inner">
                <li :class="TabActive==41?'active':''" @click="TabActive=41">分类顺序表400题够吗</li>
                <li :class="TabActive==42?'active':''" @click="TabActive=42">我想提问题</li>
              </ul>
            </li> -->
            <!-- <li :class="TabActive==5?'active':''" @click="TabActive=5">关于作者</li>
            <li :class="TabActive==6?'active':''" @click="TabActive=6">微信交流群</li> -->
            <li :class="TabActive == 7 ? 'active' : ''" @click="TabActive = 7">
              参考网站
            </li>
          </ul>
        </div>
        <div class="seqlist-rg">
          <div v-if="TabActive == 0" class="panel">
            <div class="tit">致所有使用者</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-header">
                      <img
                        src="@/assets/img/kecheng-icon.png"
                        style="vertical-align: bottom; width: 17px"
                      />
                      <strong style="font-weight: bold"
                        >Cspiration 独家出品</strong
                      >
                    </div>
                    <div class="cell-body">
                      <div class="cell-intro">
                        此表以先易后难 +
                        分类而成。Leetcode（https://leetcode-cn.com/）本身并没有顺序，
                        并且类别分的并不是非常好，因为大量非最优解也涵盖在各种类别中。
                        所以我们依据做题经验，最优解的类别，难度，重新划分。
                        如果是第一次刷题的小伙伴，最好以本书的顺序为主，可以为大家节省时间，更有效率的做题，减少很多刷题的负担。
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <div class="cell-intro">
                        注：本表非最终版本。《题型技巧总结》课程中有最终Leetcode分类顺序表版本，更细致，每种题目对应方法一并写出，
                        并且同类型做题方法归纳一起。
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <img style="width:500px;margin-top:10px" src="@/assets/img/sqlist-img1.png"> -->
              </div>
            </div>
          </div>
          <div v-if="TabActive == 11" class="panel">
            <div class="tit">Array</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData11" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 12" class="panel">
            <div class="tit">String</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData12" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 13" class="panel">
            <div class="tit">Math</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData13" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 14" class="panel">
            <div class="tit">Tree</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData14" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 15" class="panel">
            <div class="tit">Backtracking</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData15" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 16" class="panel">
            <div class="tit">Dynamic Programming</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData16" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 17" class="panel">
            <div class="tit">LinkedList</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData17" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 18" class="panel">
            <div class="tit">Binary Search</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData18" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 19" class="panel">
            <div class="tit">Matrix</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData19" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 110" class="panel">
            <div class="tit">DFS & BFS</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData110" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 111" class="panel">
            <div class="tit">Stack & PriorityQueue</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData111" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 112" class="panel">
            <div class="tit">Bit Manipulation</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData112" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 113" class="panel">
            <div class="tit">Topological Sort</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData113" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 114" class="panel">
            <div class="tit">Random</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData114" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 115" class="panel">
            <div class="tit">Graph</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData115" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 116" class="panel">
            <div class="tit">Union Find</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData116" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 117" class="panel">
            <div class="tit">Trie</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData117" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 118" class="panel">
            <div class="tit">Design</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData118" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name" width="380">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column label="说明" prop="dis"></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 1" class="panel">
            <div class="tit">关于作者</div>
            <div class="content">
              <div class="sqlistcon">
                <img
                  src="@/assets/img/sqlist-code.png"
                  style="width: 500px; margin-top: 40px"
                />
              </div>
            </div>
          </div>
          <div v-if="TabActive == 21" class="panel">
            <div class="tit">划分说明</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <p>
                        这个重点题目是把Leetcode前400题进行精简，划分精简规则如下：
                      </p>
                      <ul class="kc-list list-unstyled">
                        <li class="dot">
                          <a href="javascript:;"
                            >删除不常考，面试低频出现题目</a
                          >
                        </li>
                        <li class="dot">
                          <a href="javascript:;"
                            >删除重复代码题目（例：链表反转206题，代码在234题出现过）</a
                          >
                        </li>
                        <li class="dot">
                          <a href="javascript:;"
                            >删除过于简单题目（例：100题：Same Tree）</a
                          >
                        </li>
                        <li class="dot">
                          <a href="javascript:;"
                            >删除题意不同，代码基本相同题目（例：136 &
                            389，保留一个）</a
                          >
                        </li>
                      </ul>
                      <p>
                        所有题目尽量保证客观公正，只是按大概率删除不常考题目，很多题目面经出现过，
                        但出现次数属于个位数或者只有一两家出现进行删除。所以如在面试中出现删除题目概不负责，这只是从概率上删除低频，简单题目。
                        旨在减轻大家的刷题负担，从400题减少到250题。
                      </p>
                      <p>适用人群：有一定刷题基础，算法基础，二刷人群。</p>
                      <p class="bold">建议：400题全部刷完，再精刷这250题。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 22" class="panel">
            <div class="tit">Leetcode 前 400 重点 250 题</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData22" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <!--                    <el-table-column prop="dis" label="说明"></el-table-column>-->
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 31" class="panel">
            <div class="tit">划分说明</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <p>
                        此表是针对 Data Science
                        这个职位，对Leetcode前400题进行精简，划分精简规则如下：
                      </p>
                      <ul class="kc-list list-unstyled">
                        <li class="dot">
                          <a href="javascript:;"
                            >删除DS不常考，面试低频出现题目</a
                          >
                        </li>
                        <li class="dot">
                          <a href="javascript:;">删除SDE考，过难题目</a>
                        </li>
                        <li class="dot">
                          <a href="javascript:;"
                            >删除高级数据结构算法，保留基础简单题目</a
                          >
                        </li>
                        <li class="dot">
                          <a href="javascript:;"
                            >保留基础练手题目，提高代码能力</a
                          >
                        </li>
                      </ul>
                      <p>
                        所有题目我们尽量保证客观公正，只是按大概率删除，目的是为了减轻DS的刷题负担。
                      </p>
                      <p>适用人群：Data Science 职位相关人员</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 32" class="panel">
            <div class="tit">Data Science Leetcode 精简版</div>
            <div class="content">
              <div class="sqlistcon">
                <template>
                  <el-table :data="tableData32" stripe style="width: 100%">
                    <el-table-column
                      label="题号"
                      prop="number"
                      width="80"
                    ></el-table-column>
                    <el-table-column label="题目链接" prop="name">
                      <template slot-scope="scope">
                        <a :href="scope.row.link" target="_blank">{{
                          scope.row.name
                        }}</a>
                      </template>
                    </el-table-column>
                    <!--                    <el-table-column prop="dis" label="说明"></el-table-column>-->
                  </el-table>
                </template>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 41" class="panel">
            <div class="tit">分类顺序表400题，刷这些够吗，剩下的怎么办？</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <div class="cell-intro">
                        如果能把Leetcode
                        前400题都刷透，再做做面经题，足以面试各大公司。<br />
                        或者说，以算法题角度，突破各大公司。<br />
                        但在现实生活中，能把400题刷明白的，十中无一。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 42" class="panel">
            <div class="tit">我想提问题</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <div class="cell-intro">
                        有问题，可以在这个word里提问，近期我们会集中回答。
                        <p>
                          <a
                            href="https://docs.google.com/document/d/1Kp2OhWtqM3qZClV5sMMeR6SF4S2JxhDsDqxv2JZ6he0/edit"
                            target="_blank"
                            >提问题链接（点我）</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 5" class="panel">
            <div class="tit">Edward Shi</div>
            <div class="content">
              <div class="sqlistcon">
                <div class="card">
                  <div class="col-cell">
                    <div class="cell-body">
                      <div class="cell-intro">
                        留学两年多，刷题过三千。<br />
                        CS科班出身，但非ACMer，从没接触过ACM。从树的遍历捡起算法，纯自己刷题。<br />
                        在北美留学期间刷了3000多道题，全部都是Leetcode和面试题。自我总结出公司面试题出题方式，套路。<br />
                        独立做出针对面试刷题的课程，课程如下：<br />
                        《算法基础知识（上下）》<br />
                        《题型技巧总结（上下）》<br />
                        《Leetcode 题目视频讲解（上中下1-1000题）》
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="TabActive == 6" class="panel">
            <div class="tit">北美CS刷题求职群</div>
            <div class="content">
              <div class="sqlistcon">
                <img
                  src="@/assets/img/sqlist-img1.png"
                  style="width: 500px; margin-top: 10px"
                />
              </div>
            </div>
          </div>
          <div v-if="TabActive == 7" class="panel">
            <div class="tit">LeetCode</div>
            <div class="content">
              <div class="sqlistcon">
                <p>​https://leetcode-cn.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstOpen: false,
      secondOpen: false,
      thirdOpen: false,
      fourthOpen: false,
      tabs: [
        {
          name: "热门建议",
        },
        {
          name: "最新建议",
        },
        {
          name: "我的建议",
        },
      ],
      TabActive: 0,
      tableData: [
        {
          number: "1",
          name: "王小虎",
          dis: "说明说明说明",
          words: "视频讲解视频讲解视频讲解视",
        },
        {
          number: "2",
          name: "王小虎",
          dis: "说明说明说明",
          words: "视频讲解视频讲解视频讲解视",
        },
        {
          number: "3",
          name: "王小虎",
          dis: "说明说明说明",
          words: "视频讲解视频讲解视频讲解视",
        },
        {
          number: "4",
          name: "王小虎",
          dis: "说明说明说明",
          words: "视频讲解视频讲解视频讲解视",
        },
      ],
      tableData11: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "27",
          name: "Remove Element",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-element",
        },
        {
          number: "26",
          name: "Remove Duplicates from Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array",
        },
        {
          number: "80",
          name: "Remove Duplicates from Sorted Array II",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array-ii",
        },
        {
          number: "277",
          name: "Find the Celebrity",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-the-celebrity",
        },
        {
          number: "189",
          name: "Rotate Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/rotate-array",
        },
        {
          number: "41",
          name: "First Missing Positive",
          dis: "",
          link: "https://leetcode-cn.com/problems/first-missing-positive",
        },
        {
          number: "299",
          name: "Bulls and Cows",
          dis: "",
          link: "https://leetcode-cn.com/problems/bulls-and-cows",
        },
        {
          number: "134",
          name: "Gas Station",
          dis: "",
          link: "https://leetcode-cn.com/problems/gas-station",
        },
        {
          number: "118",
          name: "Pascal's Triangle",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/pascals-triangle",
        },
        {
          number: "119",
          name: "Pascal's Triangle II",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/pascals-triangle-ii",
        },
        {
          number: "169",
          name: "Majority Element",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/majority-element",
        },
        {
          number: "229",
          name: "Majority Element II",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/majority-element-ii",
        },
        {
          number: "274",
          name: "H-Index",
          dis: "",
          link: "https://leetcode-cn.com/problems/h-index",
        },
        {
          number: "275",
          name: "H-Index II",
          dis: "Binary Search",
          link: "https://leetcode-cn.com/problems/h-index-ii",
        },
        {
          number: "243",
          name: "Shortest Word Distance",
          dis: "",
          link: "https://leetcode-cn.com/problems/shortest-word-distance",
        },
        {
          number: "244",
          name: "Shortest Word Distance II",
          dis: "",
          link: "https://leetcode-cn.com/problems/shortest-word-distance-ii",
        },
        {
          number: "245",
          name: "Shortest Word Distance III",
          dis: "",
          link: "https://leetcode-cn.com/problems/shortest-word-distance-iii",
        },
        {
          number: "217",
          name: "Contains Duplicate",
          dis: "",
          link: "https://leetcode-cn.com/problems/contains-duplicate",
        },
        {
          number: "219",
          name: "Contains Duplicate II",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/contains-duplicate-ii",
        },
        {
          number: "220",
          name: "Contains Duplicate III",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/contains-duplicate-iii",
        },
        {
          number: "55",
          name: "Jump Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/jump-game",
        },
        {
          number: "45",
          name: "Jump Game II",
          dis: "",
          link: "https://leetcode-cn.com/problems/jump-game-ii",
        },
        {
          number: "121",
          name: "Best Time to Buy and Sell Stock",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock",
        },
        {
          number: "122",
          name: "Best Time to Buy and Sell Stock II",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-ii",
        },
        {
          number: "123",
          name: "Best Time to Buy and Sell Stock III",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-iii",
        },
        {
          number: "188",
          name: "Best Time to Buy and Sell Stock IV",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-iv",
        },
        {
          number: "309",
          name: "Best Time to Buy and Sell Stock with Cooldown",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-with-cooldown",
        },
        {
          number: "11",
          name: "Container With Most Water",
          dis: "",
          link: "https://leetcode-cn.com/problems/container-with-most-water",
        },
        {
          number: "42",
          name: "Trapping Rain Water",
          dis: "",
          link: "https://leetcode-cn.com/problems/trapping-rain-water",
        },
        {
          number: "334",
          name: "Increasing Triplet Subsequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/increasing-triplet-subsequence",
        },
        {
          number: "128",
          name: "Longest Consecutive Sequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-consecutive-sequence",
        },
        {
          number: "164",
          name: "Maximum Gap",
          dis: "Bucket",
          link: "https://leetcode-cn.com/problems/maximum-gap",
        },
        {
          number: "287",
          name: "Find the Duplicate Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-the-duplicate-number",
        },
        {
          number: "135",
          name: "Candy",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/candy",
        },
        {
          number: "330",
          name: "Patching Array",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/patching-array",
        },
        {
          number: "提高",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "4",
          name: "Median of Two Sorted Arrays",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/median-of-two-sorted-arrays",
        },
        {
          number: "321",
          name: "Create Maximum Number",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/create-maximum-number",
        },
        {
          number: "327",
          name: "Count of Range Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-of-range-sum",
        },
        {
          number: "289",
          name: "Game of Life",
          dis: "",
          link: "https://leetcode-cn.com/problems/game-of-life",
        },
        {
          number: "Interval",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "57",
          name: "Insert Interval",
          dis: "",
          link: "https://leetcode-cn.com/problems/insert-interval",
        },
        {
          number: "56",
          name: "Merge Intervals",
          dis: "",
          link: "https://leetcode-cn.com/problems/merge-intervals",
        },
        {
          number: "252",
          name: "Meeting Rooms",
          dis: "",
          link: "https://leetcode-cn.com/problems/meeting-rooms",
        },
        {
          number: "253",
          name: "Meeting Rooms II",
          dis: "",
          link: "https://leetcode-cn.com/problems/meeting-rooms-ii",
        },
        {
          number: "352",
          name: "Data Stream as Disjoint Intervals",
          dis: "TreeMap",
          link: "https://leetcode-cn.com/problems/data-stream-as-disjoint-intervals",
        },
        {
          number: "Counter",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "239",
          name: "Sliding Window Maximum",
          dis: "",
          link: "https://leetcode-cn.com/problems/sliding-window-maximum",
        },
        {
          number: "295",
          name: "Find Median from Data Stream",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-median-from-data-stream",
        },
        {
          number: "53",
          name: "Maximum Subarray",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximum-subarray",
        },
        {
          number: "325",
          name: "Maximum Size Subarray Sum Equals k",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximum-size-subarray-sum-equals-k",
        },
        {
          number: "209",
          name: "Minimum Size Subarray Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/minimum-size-subarray-sum",
        },
        {
          number: "238",
          name: "Product of Array Except Self",
          dis: "",
          link: "https://leetcode-cn.com/problems/product-of-array-except-self",
        },
        {
          number: "152",
          name: "Maximum Product Subarray",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximum-product-subarray",
        },
        {
          number: "228",
          name: "Summary Ranges",
          dis: "",
          link: "https://leetcode-cn.com/problems/summary-ranges",
        },
        {
          number: "163",
          name: "Missing Ranges",
          dis: "",
          link: "https://leetcode-cn.com/problems/missing-ranges",
        },
        {
          number: "88",
          name: "Merge Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/merge-sorted-array",
        },
        {
          number: "75",
          name: "Sort Colors",
          dis: "",
          link: "https://leetcode-cn.com/problems/sort-colors",
        },
        {
          number: "283",
          name: "Move Zeroes",
          dis: "",
          link: "https://leetcode-cn.com/problems/move-zeroes",
        },
        {
          number: "376",
          name: "Wiggle Subsequence",
          dis: "",
          link: "",
        },
        {
          number: "280",
          name: "Wiggle Sort",
          dis: "",
          link: "https://leetcode-cn.com/problems/wiggle-sort",
        },
        {
          number: "324",
          name: "Wiggle Sort II",
          dis: "",
          link: "https://leetcode-cn.com/problems/wiggle-sort-ii",
        },
        {
          number: "278",
          name: "First Bad Version",
          dis: "",
          link: "https://leetcode-cn.com/problems/first-bad-version",
        },
        {
          number: "35",
          name: "Search Insert Position",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-insert-position",
        },
        {
          number: "33",
          name: "Search in Rotated Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array",
        },
        {
          number: "81",
          name: "Search in Rotated Sorted Array II",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array-ii",
        },
        {
          number: "153",
          name: "Find Minimum in Rotated Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array",
        },
        {
          number: "154",
          name: "Find Minimum in Rotated Sorted Array II",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array-ii",
        },
        {
          number: "162",
          name: "Find Peak Element",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-peak-element",
        },
        {
          number: "374",
          name: "Guess Number Higher or Lower",
          dis: "",
          link: "https://leetcode-cn.com/problems/guess-number-higher-or-lower",
        },
        {
          number: "34",
          name: "Find First and Last Position of Element in Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-first-and-last-position-of-element-in-sorted-array",
        },
        {
          number: "349",
          name: "Intersection of Two Arrays",
          dis: "",
          link: "https://leetcode-cn.com/problems/intersection-of-two-arrays",
        },
        {
          number: "350",
          name: "Intersection of Two Arrays II",
          dis: "",
          link: "https://leetcode-cn.com/problems/intersection-of-two-arrays-ii",
        },
        {
          number: "315",
          name: "Count of Smaller Numbers After Self",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-of-smaller-numbers-after-self",
        },
        {
          number: "300",
          name: "Longest Increasing Subsequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-increasing-subsequence",
        },
        {
          number: "354",
          name: "Russian Doll Envelopes",
          dis: "",
          link: "https://leetcode-cn.com/problems/russian-doll-envelopes",
        },
      ],
      tableData12: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "28",
          name: "Implement strStr()",
          dis: "",
          link: "https://leetcode-cn.com/problems/implement-strstr",
        },
        {
          number: "14",
          name: "Longest Common Prefix",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-common-prefix",
        },
        {
          number: "58",
          name: "Length of Last Word",
          dis: "",
          link: "https://leetcode-cn.com/problems/length-of-last-word",
        },
        {
          number: "387",
          name: "First Unique Character in a String",
          dis: "",
          link: "https://leetcode-cn.com/problems/first-unique-character-in-a-string",
        },
        {
          number: "383",
          name: "Ransom Note",
          dis: "",
          link: "https://leetcode-cn.com/problems/ransom-note",
        },
        {
          number: "344",
          name: "Reverse String",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-string",
        },
        {
          number: "151",
          name: "Reverse Words in a String",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-words-in-a-string",
        },
        {
          number: "186",
          name: "Reverse Words in a String II",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-words-in-a-string-ii",
        },
        {
          number: "345",
          name: "Reverse Vowels of a String",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-vowels-of-a-string",
        },
        {
          number: "205",
          name: "Isomorphic Strings",
          dis: "",
          link: "https://leetcode-cn.com/problems/isomorphic-strings",
        },
        {
          number: "293",
          name: "Flip Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/flip-game",
        },
        {
          number: "294",
          name: "Flip Game II",
          dis: "",
          link: "https://leetcode-cn.com/problems/flip-game-ii",
        },
        {
          number: "290",
          name: "Word Pattern",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-pattern",
        },
        {
          number: "242",
          name: "Valid Anagram",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-anagram",
        },
        {
          number: "49",
          name: "Group Anagrams",
          dis: "",
          link: "https://leetcode-cn.com/problems/group-anagrams",
        },
        {
          number: "249",
          name: "Group Shifted Strings",
          dis: "",
          link: "https://leetcode-cn.com/problems/group-shifted-strings",
        },
        {
          number: "87",
          name: "Scramble String",
          dis: "",
          link: "https://leetcode-cn.com/problems/scramble-string",
        },
        {
          number: "179",
          name: "Largest Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/largest-number",
        },
        {
          number: "6",
          name: "ZigZag Conversion",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/zigzag-conversion",
        },
        {
          number: "161",
          name: "One Edit Distance",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/one-edit-distance",
        },
        {
          number: "38",
          name: "Count and Say",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-and-say",
        },
        {
          number: "358",
          name: "Rearrange String k Distance Apart",
          dis: "",
          link: "https://leetcode-cn.com/problems/rearrange-string-k-distance-apart",
        },
        {
          number: "316",
          name: "Remove Duplicate Letters",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-duplicate-letters",
        },
        {
          number: "271",
          name: "Encode and Decode Strings",
          dis: "",
          link: "https://leetcode-cn.com/problems/encode-and-decode-strings",
        },
        {
          number: "168",
          name: "Excel Sheet Column Title",
          dis: "",
          link: "https://leetcode-cn.com/problems/excel-sheet-column-title",
        },
        {
          number: "171",
          name: "Excel Sheet Column Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/excel-sheet-column-number",
        },
        {
          number: "13",
          name: "Roman to Integer",
          dis: "",
          link: "https://leetcode-cn.com/problems/roman-to-integer",
        },
        {
          number: "12",
          name: "Integer to Roman",
          dis: "",
          link: "https://leetcode-cn.com/problems/integer-to-roman",
        },
        {
          number: "273",
          name: "Integer to English Words",
          dis: "",
          link: "https://leetcode-cn.com/problems/integer-to-english-words",
        },
        {
          number: "246",
          name: "Strobogrammatic Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/strobogrammatic-number",
        },
        {
          number: "247",
          name: "Strobogrammatic Number II",
          dis: "",
          link: "https://leetcode-cn.com/problems/strobogrammatic-number-ii",
        },
        {
          number: "248",
          name: "Strobogrammatic Number III",
          dis: "",
          link: "https://leetcode-cn.com/problems/strobogrammatic-number-iii",
        },
        {
          number: "",
          name: "",
          dis: "很少考",
          link: "",
        },
        {
          number: "提高",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "157",
          name: "Read N Characters Given Read4",
          dis: "",
          link: "https://leetcode-cn.com/problems/read-n-characters-given-read4",
        },
        {
          number: "158",
          name: "Read N Characters Given Read4 II - Call multiple times",
          dis: "",
          link: "https://leetcode-cn.com/problems/read-n-characters-given-read4-ii-call-multiple-times",
        },
        {
          number: "68",
          name: "Text Justification",
          dis: "",
          link: "https://leetcode-cn.com/problems/text-justification",
        },
        {
          number: "65",
          name: "Valid Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-number",
        },
        {
          number: "Substring",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "76",
          name: "Minimum Window Substring",
          dis: "",
          link: "https://leetcode-cn.com/problems/minimum-window-substring",
        },
        {
          number: "30",
          name: "Substring with Concatenation of All Words",
          dis: "Sliding Window",
          link: "https://leetcode-cn.com/problems/substring-with-concatenation-of-all-words",
        },
        {
          number: "3",
          name: "Longest Substring Without Repeating Characters",
          dis: "Sliding Window",
          link: "https://leetcode-cn.com/problems/longest-substring-without-repeating-characters",
        },
        {
          number: "340",
          name: "Longest Substring with At Most K Distinct Characters",
          dis: "Sliding Window",
          link: "https://leetcode-cn.com/problems/longest-substring-with-at-most-k-distinct-characters",
        },
        {
          number: "395",
          name: "Longest Substring with At Least K Repeating Characters",
          dis: "Sliding Window",
          link: "https://leetcode-cn.com/problems/longest-substring-with-at-least-k-repeating-characters",
        },
        {
          number: "159",
          name: "Longest Substring with At Most Two Distinct Characters",
          dis: "Sliding Window",
          link: "",
        },
        {
          number: "Palindrome",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "125",
          name: "Valid Palindrome",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-palindrome",
        },
        {
          number: "266",
          name: "Palindrome Permutation",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-permutation",
        },
        {
          number: "5",
          name: "Longest Palindromic Substring",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-palindromic-substring",
        },
        {
          number: "9",
          name: "Palindrome Number",
          dis: "",
          link: "",
        },
        {
          number: "214",
          name: "Shortest Palindrome",
          dis: "",
          link: "https://leetcode-cn.com/problems/shortest-palindrome",
        },
        {
          number: "336",
          name: "Palindrome Pairs",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-pairs",
        },
        {
          number: "131",
          name: "Palindrome Partitioning",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-partitioning",
        },
        {
          number: "132",
          name: "Palindrome Partitioning II",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-partitioning-ii",
        },
        {
          number: "267",
          name: "Palindrome Permutation II",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-permutation-ii",
        },
        {
          number: "20",
          name: "Valid Parentheses",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-parentheses",
        },
        {
          number: "22",
          name: "Generate Parentheses",
          dis: "",
          link: "https://leetcode-cn.com/problems/generate-parentheses",
        },
        {
          number: "32",
          name: "Longest Valid Parentheses",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-valid-parentheses",
        },
        {
          number: "241",
          name: "Different Ways to Add Parentheses",
          dis: "",
          link: "https://leetcode-cn.com/problems/different-ways-to-add-parentheses",
        },
        {
          number: "301",
          name: "Remove Invalid Parentheses",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-invalid-parentheses",
        },
        {
          number: "392",
          name: "Is Subsequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/is-subsequence",
        },
        {
          number: "115",
          name: "Distinct Subsequences",
          dis: "",
          link: "https://leetcode-cn.com/problems/distinct-subsequences",
        },
        {
          number: "187",
          name: "Repeated DNA Sequences",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/repeated-dna-sequences",
        },
      ],
      //Math
      tableData13: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "7",
          name: "Reverse Integer",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-integer",
        },
        {
          number: "165",
          name: "Compare Version Numbers",
          dis: "",
          link: "https://leetcode-cn.com/problems/compare-version-numbers",
        },
        {
          number: "66",
          name: "Plus One",
          dis: "",
          link: "https://leetcode-cn.com/problems/plus-one",
        },
        {
          number: "8",
          name: "String to Integer (atoi)",
          dis: "",
          link: "https://leetcode-cn.com/problems/string-to-integer-atoi",
        },
        {
          number: "258",
          name: "Add Digits",
          dis: "",
          link: "https://leetcode-cn.com/problems/add-digits",
        },
        {
          number: "67",
          name: "Add Binary",
          dis: "",
          link: "https://leetcode-cn.com/problems/add-binary",
        },
        {
          number: "43",
          name: "Multiply Strings",
          dis: "",
          link: "https://leetcode-cn.com/problems/multiply-strings",
        },
        {
          number: "29",
          name: "Divide Two Integers",
          dis: "",
          link: "https://leetcode-cn.com/problems/divide-two-integers",
        },
        {
          number: "69",
          name: "Sqrt(x)",
          dis: "",
          link: "https://leetcode-cn.com/problems/sqrtx",
        },
        {
          number: "50",
          name: "Pow(x, n)",
          dis: "",
          link: "https://leetcode-cn.com/problems/powx-n",
        },
        {
          number: "367",
          name: "Valid Perfect Square",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-perfect-square",
        },
        {
          number: "365",
          name: "Water and Jug Problem",
          dis: "",
          link: "https://leetcode-cn.com/problems/water-and-jug-problem",
        },
        {
          number: "204",
          name: "Count Primes",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-primes",
        },
        {
          number: "Sum",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "1",
          name: "Two Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/two-sum",
        },
        {
          number: "167",
          name: "Two Sum II - Input array is sorted",
          dis: "",
          link: "https://leetcode-cn.com/problems/two-sum-ii-input-array-is-sorted",
        },
        {
          number: "15",
          name: "3Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/3sum",
        },
        {
          number: "16",
          name: "3Sum Closest",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/3sum-closest",
        },
        {
          number: "259",
          name: "3Sum Smaller",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/3sum-smaller",
        },
        {
          number: "18",
          name: "4Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/4sum",
        },
        {
          number: "很少考",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "231",
          name: "Power of Two",
          dis: "",
          link: "https://leetcode-cn.com/problems/power-of-two",
        },
        {
          number: "326",
          name: "Power of Three",
          dis: "",
          link: "",
        },
        {
          number: "342",
          name: "Power of Four",
          dis: "",
          link: "https://leetcode-cn.com/problems/power-of-four",
        },
        {
          number: "372",
          name: "Super Pow",
          dis: "",
          link: "https://leetcode-cn.com/problems/super-pow",
        },
        {
          number: "233",
          name: "Number of Digit One",
          dis: "",
          link: "https://leetcode-cn.com/problems/number-of-digit-one",
        },
        {
          number: "319",
          name: "Bulb Switcher",
          dis: "",
          link: "https://leetcode-cn.com/problems/bulb-switcher",
        },
        {
          number: "292",
          name: "Nim Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/nim-game",
        },
        {
          number: "202",
          name: "Happy Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/happy-number",
        },
        {
          number: "400",
          name: "Nth Digit",
          dis: "",
          link: "https://leetcode-cn.com/problems/nth-digit",
        },
        {
          number: "263",
          name: "Ugly Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/ugly-number",
        },
        {
          number: "264",
          name: "Ugly Number II",
          dis: "",
          link: "https://leetcode-cn.com/problems/ugly-number-ii",
        },
        {
          number: "306",
          name: "Additive Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/additive-number",
        },
        {
          number: "172",
          name: "Factorial Trailing Zeroes",
          dis: "",
          link: "https://leetcode-cn.com/problems/factorial-trailing-zeroes",
        },
        {
          number: "343",
          name: "Integer Break",
          dis: "",
          link: "https://leetcode-cn.com/problems/integer-break",
        },
        {
          number: "396",
          name: "Rotate Function",
          dis: "",
          link: "https://leetcode-cn.com/problems/rotate-function",
        },
        {
          number: "390",
          name: "Elimination Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/elimination-game",
        },
        {
          number: "386",
          name: "Lexicographical Numbers",
          dis: "",
          link: "https://leetcode-cn.com/problems/lexicographical-numbers",
        },
        {
          number: "357",
          name: "Count Numbers with Unique Digits",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-numbers-with-unique-digits",
        },
        {
          number: "360",
          name: "Sort Transformed Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/sort-transformed-array",
        },
        {
          number: "397",
          name: "Integer Replacement",
          dis: "",
          link: "https://leetcode-cn.com/problems/integer-replacement",
        },
        {
          number: "368",
          name: "Largest Divisible Subset",
          dis: "",
          link: "https://leetcode-cn.com/problems/largest-divisible-subset",
        },
      ],
      //Tree
      tableData14: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "144",
          name: "Binary Tree Preorder Traversal",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-preorder-traversal",
        },
        {
          number: "94",
          name: "Binary Tree Inorder Traversal",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-inorder-traversal",
        },
        {
          number: "145",
          name: "Binary Tree Postorder Traversal",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-postorder-traversal",
        },
        {
          number: "102",
          name: "Binary Tree Level Order Traversal",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-level-order-traversal",
        },
        {
          number: "Preorder",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "100",
          name: "Same Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/same-tree",
        },
        {
          number: "101",
          name: "Symmetric Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/symmetric-tree",
        },
        {
          number: "226",
          name: "Invert Binary Tree",
          dis: "preorder + BFS",
          link: "https://leetcode-cn.com/problems/invert-binary-tree",
        },
        {
          number: "257",
          name: "Binary Tree Paths",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-paths",
        },
        {
          number: "112",
          name: "Path Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/path-sum",
        },
        {
          number: "113",
          name: "Path Sum II",
          dis: "",
          link: "https://leetcode-cn.com/problems/path-sum-ii",
        },
        {
          number: "129",
          name: "Sum Root to Leaf Numbers",
          dis: "",
          link: "https://leetcode-cn.com/problems/sum-root-to-leaf-numbers",
        },
        {
          number: "298",
          name: "Binary Tree Longest Consecutive Sequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-longest-consecutive-sequence",
        },
        {
          number: "111",
          name: "Minimum Depth of Binary Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/minimum-depth-of-binary-tree",
        },
        {
          number: "104",
          name: "Maximum Depth of Binary Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximum-depth-of-binary-tree",
        },
        {
          number: "110",
          name: "Balanced Binary Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/balanced-binary-tree",
        },
        {
          number: "124",
          name: "Binary Tree Maximum Path Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-maximum-path-sum",
        },
        {
          number: "250",
          name: "Count Univalue Subtrees",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-univalue-subtrees",
        },
        {
          number: "366",
          name: "Find Leaves of Binary Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-leaves-of-binary-tree",
        },
        {
          number: "337",
          name: "House Robber III",
          dis: "postorder + preorder",
          link: "https://leetcode-cn.com/problems/house-robber-iii",
        },
        {
          number: "BFS",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "107",
          name: "Binary Tree Level Order Traversal II",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-level-order-traversal-ii",
        },
        {
          number: "103",
          name: "Binary Tree Zigzag Level Order Traversal",
          dis: "",
          link: "https://leetcode-cn.com/problems/binary-tree-zigzag-level-order-traversal",
        },
        {
          number: "199",
          name: "Binary Tree Right Side View",
          dis: "BFS + preorder",
          link: "https://leetcode-cn.com/problems/binary-tree-right-side-view",
        },
        {
          number: "98",
          name: "Validate Binary Search Tree",
          dis: "preorder",
          link: "https://leetcode-cn.com/problems/validate-binary-search-tree",
        },
        {
          number: "235",
          name: "Lowest Common Ancestor of a Binary Search Tree",
          dis: "preorder",
          link: "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-search-tree",
        },
        {
          number: "236",
          name: "Lowest Common Ancestor of a Binary Tree",
          dis: "postorder",
          link: "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-tree",
        },
        {
          number: "108",
          name: "Convert Sorted Array to Binary Search Tree",
          dis: "binary search",
          link: "https://leetcode-cn.com/problems/convert-sorted-array-to-binary-search-tree",
        },
        {
          number: "109",
          name: "Convert Sorted List to Binary Search Tree",
          dis: "binary search",
          link: "",
        },
        {
          number: "173",
          name: "Binary Search Tree Iterator",
          dis: "inorder",
          link: "https://leetcode-cn.com/problems/binary-search-tree-iterator",
        },
        {
          number: "230",
          name: "Kth Smallest Element in a BST",
          dis: "inorder",
          link: "https://leetcode-cn.com/problems/kth-smallest-element-in-a-bst",
        },
        {
          number: "297",
          name: "Serialize and Deserialize Binary Tree",
          dis: "BFS",
          link: "https://leetcode-cn.com/problems/serialize-and-deserialize-binary-tree",
        },
        {
          number: "285",
          name: "Inorder Successor in BST",
          dis: "inorder",
          link: "https://leetcode-cn.com/problems/inorder-successor-in-bst",
        },
        {
          number: "270",
          name: "Closest Binary Search Tree Value",
          dis: "preorder",
          link: "https://leetcode-cn.com/problems/closest-binary-search-tree-value",
        },
        {
          number: "272",
          name: "Closest Binary Search Tree Value II",
          dis: "inorder",
          link: "https://leetcode-cn.com/problems/closest-binary-search-tree-value-ii",
        },
        {
          number: "99",
          name: "Recover Binary Search Tree",
          dis: "inorder",
          link: "https://leetcode-cn.com/problems/recover-binary-search-tree",
        },
        {
          number: "重要程度",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "156",
          name: "Binary Tree Upside Down",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/binary-tree-upside-down",
        },
        {
          number: "114",
          name: "Flatten Binary Tree to Linked List",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/flatten-binary-tree-to-linked-list",
        },
        {
          number: "255",
          name: "Verify Preorder Sequence in Binary Search Tree",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/verify-preorder-sequence-in-binary-search-tree",
        },
        {
          number: "333",
          name: "Largest BST Subtree",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/largest-bst-subtree",
        },
        {
          number: "222",
          name: "Count Complete Tree Nodes",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/count-complete-tree-nodes",
        },
        {
          number: "105",
          name: "Construct Binary Tree from Preorder and Inorder Traversal",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/construct-binary-tree-from-preorder-and-inorder-traversal",
        },
        {
          number: "106",
          name: "Construct Binary Tree from Inorder and Postorder Traversal",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/construct-binary-tree-from-inorder-and-postorder-traversal",
        },
        {
          number: "116",
          name: "Populating Next Right Pointers in Each Node",
          dis: "重要",
          link: "https://leetcode-cn.com/problems/populating-next-right-pointers-in-each-node",
        },
        {
          number: "117",
          name: "Populating Next Right Pointers in Each Node II",
          dis: "重要",
          link: "https://leetcode-cn.com/problems/populating-next-right-pointers-in-each-node-ii",
        },
        {
          number: "314",
          name: "Binary Tree Vertical Order Traversal",
          dis: "重要",
          link: "https://leetcode-cn.com/problems/binary-tree-vertical-order-traversal",
        },
        {
          number: "96",
          name: "Unique Binary Search Trees",
          dis: "重要",
          link: "https://leetcode-cn.com/problems/unique-binary-search-trees",
        },
        {
          number: "95",
          name: "Unique Binary Search Trees II",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/unique-binary-search-trees-ii",
        },
        {
          number: "331",
          name: "Verify Preorder Serialization of a Binary Tree",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/verify-preorder-serialization-of-a-binary-tree",
        },
      ],
      //Backtracking
      tableData15: [
        {
          number: "78",
          name: "Subsets",
          dis: "",
          link: "https://leetcode-cn.com/problems/subsets",
        },
        {
          number: "90",
          name: "Subsets II",
          dis: "",
          link: "https://leetcode-cn.com/problems/subsets-ii",
        },
        {
          number: "77",
          name: "Combinations",
          dis: "",
          link: "https://leetcode-cn.com/problems/combinations",
        },
        {
          number: "39",
          name: "Combination Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/combination-sum",
        },
        {
          number: "40",
          name: "Combination Sum II",
          dis: "",
          link: "https://leetcode-cn.com/problems/combination-sum-ii",
        },
        {
          number: "216",
          name: "Combination Sum III",
          dis: "",
          link: "https://leetcode-cn.com/problems/combination-sum-iii",
        },
        {
          number: "377",
          name: "Combination Sum IV",
          dis: "Dynamic Programming",
          link: "https://leetcode-cn.com/problems/combination-sum-iv",
        },
        {
          number: "254",
          name: "Factor Combinations",
          dis: "",
          link: "https://leetcode-cn.com/problems/factor-combinations",
        },
        {
          number: "46",
          name: "Permutations",
          dis: "",
          link: "https://leetcode-cn.com/problems/permutations",
        },
        {
          number: "47",
          name: "Permutations II",
          dis: "",
          link: "https://leetcode-cn.com/problems/permutations-ii",
        },
        {
          number: "31",
          name: "Next Permutation",
          dis: "String",
          link: "https://leetcode-cn.com/problems/next-permutation",
        },
        {
          number: "60",
          name: "Permutation Sequence",
          dis: "String",
          link: "https://leetcode-cn.com/problems/permutation-sequence",
        },
        {
          number: "291",
          name: "Word Pattern II",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-pattern-ii",
        },
        {
          number: "17",
          name: "Letter Combinations of a Phone Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/letter-combinations-of-a-phone-number",
        },
        {
          number: "320",
          name: "Generalized Abbreviation",
          dis: "",
          link: "https://leetcode-cn.com/problems/generalized-abbreviation",
        },
        {
          number: "93",
          name: "Restore IP Addresses",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/restore-ip-addresses",
        },
        {
          number: "282",
          name: "Expression Add Operators",
          dis: "",
          link: "https://leetcode-cn.com/problems/expression-add-operators",
        },
        {
          number: "140",
          name: "Word Break II",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-break-ii",
        },
        {
          number: "351",
          name: "Android Unlock Patterns",
          dis: "",
          link: "https://leetcode-cn.com/problems/android-unlock-patterns",
        },
      ],
      //Dynamic Programming
      tableData16: [
        {
          number: "一维",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "70",
          name: "Climbing Stairs",
          dis: "",
          link: "https://leetcode-cn.com/problems/climbing-stairs",
        },
        {
          number: "62",
          name: "Unique Paths",
          dis: "",
          link: "https://leetcode-cn.com/problems/unique-paths",
        },
        {
          number: "63",
          name: "Unique Paths II",
          dis: "",
          link: "https://leetcode-cn.com/problems/unique-paths-ii",
        },
        {
          number: "120",
          name: "Triangle",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/triangle",
        },
        {
          number: "279",
          name: "Perfect Squares",
          dis: "",
          link: "https://leetcode-cn.com/problems/perfect-squares",
        },
        {
          number: "139",
          name: "Word Break",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-break",
        },
        {
          number: "375",
          name: "Guess Number Higher or Lower II",
          dis: "",
          link: "https://leetcode-cn.com/problems/guess-number-higher-or-lower-ii",
        },
        {
          number: "312",
          name: "Burst Balloons",
          dis: "",
          link: "https://leetcode-cn.com/problems/burst-balloons",
        },
        {
          number: "322",
          name: "Coin Change",
          dis: "",
          link: "https://leetcode-cn.com/problems/coin-change",
        },
        {
          number: "二维",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "256",
          name: "Paint House",
          dis: "",
          link: "https://leetcode-cn.com/problems/paint-house",
        },
        {
          number: "265",
          name: "Paint House II",
          dis: "",
          link: "https://leetcode-cn.com/problems/paint-house-ii",
        },
        {
          number: "64",
          name: "Minimum Path Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/minimum-path-sum",
        },
        {
          number: "72",
          name: "Edit Distance",
          dis: "",
          link: "https://leetcode-cn.com/problems/edit-distance",
        },
        {
          number: "97",
          name: "Interleaving String",
          dis: "",
          link: "https://leetcode-cn.com/problems/interleaving-string",
        },
        {
          number: "174",
          name: "Dungeon Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/dungeon-game",
        },
        {
          number: "221",
          name: "Maximal Square",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximal-square",
        },
        {
          number: "85",
          name: "Maximal Rectangle",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximal-rectangle",
        },
        {
          number: "363",
          name: "Max Sum of Rectangle No Larger Than K",
          dis: "TreeSet",
          link: "https://leetcode-cn.com/problems/max-sum-of-rectangle-no-larger-than-k",
        },
        {
          number: "化简",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "198",
          name: "House Robber",
          dis: "",
          link: "https://leetcode-cn.com/problems/house-robber",
        },
        {
          number: "213",
          name: "House Robber II",
          dis: "",
          link: "https://leetcode-cn.com/problems/house-robber-ii",
        },
        {
          number: "276",
          name: "Paint Fence",
          dis: "",
          link: "",
        },
        {
          number: "91",
          name: "Decode Ways",
          dis: "",
          link: "https://leetcode-cn.com/problems/decode-ways",
        },
        {
          number: "10",
          name: "Regular Expression Matching",
          dis: "",
          link: "https://leetcode-cn.com/problems/regular-expression-matching",
        },
        {
          number: "44",
          name: "Wildcard Matching",
          dis: "",
          link: "https://leetcode-cn.com/problems/wildcard-matching",
        },
      ],
      //LinkedList
      tableData17: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "206",
          name: "Reverse Linked List",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-linked-list",
        },
        {
          number: "141",
          name: "Linked List Cycle",
          dis: "",
          link: "https://leetcode-cn.com/problems/linked-list-cycle",
        },
        {
          number: "24",
          name: "Swap Nodes in Pairs",
          dis: "",
          link: "https://leetcode-cn.com/problems/swap-nodes-in-pairs",
        },
        {
          number: "328",
          name: "Odd Even Linked List",
          dis: "",
          link: "https://leetcode-cn.com/problems/odd-even-linked-list",
        },
        {
          number: "92",
          name: "Reverse Linked List II",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-linked-list-ii",
        },
        {
          number: "237",
          name: "Delete Node in a Linked List",
          dis: "",
          link: "https://leetcode-cn.com/problems/delete-node-in-a-linked-list",
        },
        {
          number: "19",
          name: "Remove Nth Node From End of List",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-nth-node-from-end-of-list",
        },
        {
          number: "83",
          name: "Remove Duplicates from Sorted List",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-list",
        },
        {
          number: "203",
          name: "Remove Linked List Elements",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-linked-list-elements",
        },
        {
          number: "82",
          name: "Remove Duplicates from Sorted List II",
          dis: "",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-list-ii",
        },
        {
          number: "369",
          name: "Plus One Linked List",
          dis: "",
          link: "https://leetcode-cn.com/problems/plus-one-linked-list",
        },
        {
          number: "2",
          name: "Add Two Numbers",
          dis: "",
          link: "https://leetcode-cn.com/problems/add-two-numbers",
        },
        {
          number: "160",
          name: "Intersection of Two Linked Lists",
          dis: "",
          link: "https://leetcode-cn.com/problems/intersection-of-two-linked-lists",
        },
        {
          number: "21",
          name: "Merge Two Sorted Lists",
          dis: "",
          link: "https://leetcode-cn.com/problems/merge-two-sorted-lists",
        },
        {
          number: "提高",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "234",
          name: "Palindrome Linked List",
          dis: "",
          link: "https://leetcode-cn.com/problems/palindrome-linked-list",
        },
        {
          number: "143",
          name: "Reorder List",
          dis: "",
          link: "https://leetcode-cn.com/problems/reorder-list",
        },
        {
          number: "142",
          name: "Linked List Cycle II",
          dis: "",
          link: "https://leetcode-cn.com/problems/linked-list-cycle-ii",
        },
        {
          number: "148",
          name: "Sort List",
          dis: "",
          link: "https://leetcode-cn.com/problems/sort-list",
        },
        {
          number: "25",
          name: "Reverse Nodes in k-Group",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-nodes-in-k-group",
        },
        {
          number: "61",
          name: "Rotate List",
          dis: "",
          link: "https://leetcode-cn.com/problems/rotate-list",
        },
        {
          number: "86",
          name: "Partition List",
          dis: "",
          link: "https://leetcode-cn.com/problems/partition-list",
        },
        {
          number: "23",
          name: "Merge k Sorted Lists",
          dis: "",
          link: "https://leetcode-cn.com/problems/merge-k-sorted-lists",
        },
        {
          number: "147",
          name: "Insertion Sort List",
          dis: "",
          link: "https://leetcode-cn.com/problems/insertion-sort-list",
        },
      ],
      //Binary Search
      tableData18: [
        {
          number: "278",
          name: "First Bad Version",
          dis: "",
          link: "https://leetcode-cn.com/problems/first-bad-version",
        },
        {
          number: "35",
          name: "Search Insert Position",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-insert-position",
        },
        {
          number: "33",
          name: "Search in Rotated Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array",
        },
        {
          number: "81",
          name: "Search in Rotated Sorted Array II",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array-ii",
        },
        {
          number: "153",
          name: "Find Minimum in Rotated Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array",
        },
        {
          number: "154",
          name: "Find Minimum in Rotated Sorted Array II",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array-ii",
        },
        {
          number: "162",
          name: "Find Peak Element",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-peak-element",
        },
        {
          number: "374",
          name: "Guess Number Higher or Lower",
          dis: "",
          link: "https://leetcode-cn.com/problems/guess-number-higher-or-lower",
        },
        {
          number: "34",
          name: "Find First and Last Position of Element in Sorted Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-first-and-last-position-of-element-in-sorted-array",
        },
        {
          number: "349",
          name: "Intersection of Two Arrays",
          dis: "",
          link: "https://leetcode-cn.com/problems/intersection-of-two-arrays",
        },
        {
          number: "350",
          name: "Intersection of Two Arrays II",
          dis: "",
          link: "https://leetcode-cn.com/problems/intersection-of-two-arrays-ii",
        },
        {
          number: "315",
          name: "Count of Smaller Numbers After Self",
          dis: "",
          link: "https://leetcode-cn.com/problems/count-of-smaller-numbers-after-self",
        },
        {
          number: "300",
          name: "Longest Increasing Subsequence",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-increasing-subsequence",
        },
        {
          number: "354",
          name: "Russian Doll Envelopes",
          dis: "",
          link: "https://leetcode-cn.com/problems/russian-doll-envelopes",
        },
      ],
      //Matrix
      tableData19: [
        {
          number: "48",
          name: "Rotate Image",
          dis: "",
          link: "https://leetcode-cn.com/problems/rotate-image",
        },
        {
          number: "54",
          name: "Spiral Matrix",
          dis: "",
          link: "https://leetcode-cn.com/problems/spiral-matrix",
        },
        {
          number: "59",
          name: "Spiral Matrix II",
          dis: "",
          link: "",
        },
        {
          number: "73",
          name: "Set Matrix Zeroes",
          dis: "",
          link: "https://leetcode-cn.com/problems/set-matrix-zeroes",
        },
        {
          number: "311",
          name: "Sparse Matrix Multiplication",
          dis: "",
          link: "https://leetcode-cn.com/problems/sparse-matrix-multiplication",
        },
        {
          number: "329",
          name: "Longest Increasing Path in a Matrix",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-increasing-path-in-a-matrix",
        },
        {
          number: "378",
          name: "Kth Smallest Element in a Sorted Matrix",
          dis: "",
          link: "https://leetcode-cn.com/problems/kth-smallest-element-in-a-sorted-matrix",
        },
        {
          number: "74",
          name: "Search a 2D Matrix",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-a-2d-matrix",
        },
        {
          number: "240",
          name: "Search a 2D Matrix II",
          dis: "",
          link: "https://leetcode-cn.com/problems/search-a-2d-matrix-ii",
        },
        {
          number: "370",
          name: "Range Addition",
          dis: "",
          link: "https://leetcode-cn.com/problems/range-addition",
        },
        {
          number: "79",
          name: "Word Search",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-search",
        },
        {
          number: "296",
          name: "Best Meeting Point",
          dis: "",
          link: "https://leetcode-cn.com/problems/best-meeting-point",
        },
        {
          number: "361",
          name: "Bomb Enemy",
          dis: "",
          link: "https://leetcode-cn.com/problems/bomb-enemy",
        },
        {
          number: "317",
          name: "Shortest Distance from All Buildings",
          dis: "",
          link: "https://leetcode-cn.com/problems/shortest-distance-from-all-buildings",
        },
        {
          number: "302",
          name: "Smallest Rectangle Enclosing Black Pixels",
          dis: "",
          link: "https://leetcode-cn.com/problems/smallest-rectangle-enclosing-black-pixels",
        },
        {
          number: "36",
          name: "Valid Sudoku",
          dis: "",
          link: "https://leetcode-cn.com/problems/valid-sudoku",
        },
        {
          number: "37",
          name: "Sudoku Solver",
          dis: "",
          link: "https://leetcode-cn.com/problems/sudoku-solver",
        },
      ],
      // DFS & BFS
      tableData110: [
        {
          number: "200",
          name: "Number of Islands",
          dis: "",
          link: "https://leetcode-cn.com/problems/number-of-islands",
        },
        {
          number: "286",
          name: "Walls and Gates",
          dis: "",
          link: "https://leetcode-cn.com/problems/walls-and-gates",
        },
        {
          number: "130",
          name: "Surrounded Regions",
          dis: "",
          link: "https://leetcode-cn.com/problems/surrounded-regions",
        },
        {
          number: "339",
          name: "Nested List Weight Sum",
          dis: "",
          link: "https://leetcode-cn.com/problems/nested-list-weight-sum",
        },
        {
          number: "364",
          name: "Nested List Weight Sum II",
          dis: "",
          link: "https://leetcode-cn.com/problems/nested-list-weight-sum-ii",
        },
        {
          number: "127",
          name: "Word Ladder",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-ladder",
        },
        {
          number: "51",
          name: "N-Queens",
          dis: "",
          link: "https://leetcode-cn.com/problems/n-queens",
        },
        {
          number: "52",
          name: "N-Queens II",
          dis: "",
          link: "https://leetcode-cn.com/problems/n-queens-ii",
        },
        {
          number: "126",
          name: "Word Ladder II",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-ladder-ii",
        },
      ],
      // Stack & PriorityQueue
      tableData111: [
        {
          number: "Stack",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "155",
          name: "Min Stack",
          dis: "",
          link: "https://leetcode-cn.com/problems/min-stack",
        },
        {
          number: "232",
          name: "Implement Queue using Stacks",
          dis: "",
          link: "https://leetcode-cn.com/problems/implement-queue-using-stacks",
        },
        {
          number: "225",
          name: "Implement Stack using Queues",
          dis: "",
          link: "",
        },
        {
          number: "150",
          name: "Evaluate Reverse Polish Notation",
          dis: "",
          link: "https://leetcode-cn.com/problems/evaluate-reverse-polish-notation",
        },
        {
          number: "71",
          name: "Simplify Path",
          dis: "",
          link: "https://leetcode-cn.com/problems/simplify-path",
        },
        {
          number: "388",
          name: "Longest Absolute File Path",
          dis: "",
          link: "https://leetcode-cn.com/problems/longest-absolute-file-path",
        },
        {
          number: "394",
          name: "Decode String",
          dis: "",
          link: "https://leetcode-cn.com/problems/decode-string",
        },
        {
          number: "224",
          name: "Basic Calculator",
          dis: "",
          link: "https://leetcode-cn.com/problems/basic-calculator",
        },
        {
          number: "227",
          name: "Basic Calculator II",
          dis: "",
          link: "https://leetcode-cn.com/problems/basic-calculator-ii",
        },
        {
          number: "385",
          name: "Mini Parser",
          dis: "",
          link: "https://leetcode-cn.com/problems/mini-parser",
        },
        {
          number: "84",
          name: "Largest Rectangle in Histogram",
          dis: "",
          link: "https://leetcode-cn.com/problems/largest-rectangle-in-histogram",
        },
        {
          number: "PriorityQueue",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "215",
          name: "Kth Largest Element in an Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/kth-largest-element-in-an-array",
        },
        {
          number: "347",
          name: "Top K Frequent Elements",
          dis: "",
          link: "https://leetcode-cn.com/problems/top-k-frequent-elements",
        },
        {
          number: "313",
          name: "Super Ugly Number",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/super-ugly-number",
        },
        {
          number: "373",
          name: "Find K Pairs with Smallest Sums",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/find-k-pairs-with-smallest-sums",
        },
        {
          number: "218",
          name: "The Skyline Problem",
          dis: "",
          link: "https://leetcode-cn.com/problems/the-skyline-problem",
        },
        {
          number: "332",
          name: "Reconstruct Itinerary",
          dis: "",
          link: "https://leetcode-cn.com/problems/reconstruct-itinerary",
        },
        {
          number: "341",
          name: "Flatten Nested List Iterator",
          dis: "",
          link: "https://leetcode-cn.com/problems/flatten-nested-list-iterator",
        },
      ],
      //Bit Manipulation
      tableData112: [
        {
          number: "389",
          name: "Find the Difference",
          dis: "",
          link: "https://leetcode-cn.com/problems/find-the-difference",
        },
        {
          number: "136",
          name: "Single Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/single-number",
        },
        {
          number: "318",
          name: "Maximum Product of Word Lengths",
          dis: "",
          link: "https://leetcode-cn.com/problems/maximum-product-of-word-lengths",
        },
        {
          number: "很少考",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "393",
          name: "UTF-8 Validation",
          dis: "",
          link: "https://leetcode-cn.com/problems/utf-8-validation",
        },
        {
          number: "201",
          name: "Bitwise AND of Numbers Range",
          dis: "",
          link: "https://leetcode-cn.com/problems/bitwise-and-of-numbers-range",
        },
        {
          number: "371",
          name: "Sum of Two Integers emove Element",
          dis: "",
          link: "https://leetcode-cn.com/problems/sum-of-two-integers",
        },
        {
          number: "338",
          name: "Counting Bits",
          dis: "",
          link: "https://leetcode-cn.com/problems/counting-bits",
        },
        {
          number: "89",
          name: "Gray Code",
          dis: "",
          link: "https://leetcode-cn.com/problems/gray-code",
        },
        {
          number: "268",
          name: "Missing Number",
          dis: "",
          link: "https://leetcode-cn.com/problems/missing-number",
        },
        {
          number: "191",
          name: "Number of 1 Bits",
          dis: "",
          link: "https://leetcode-cn.com/problems/number-of-1-bits",
        },
        {
          number: "190",
          name: "Reverse Bits",
          dis: "",
          link: "https://leetcode-cn.com/problems/reverse-bits",
        },
        {
          number: "137",
          name: "Single Number II",
          dis: "",
          link: "https://leetcode-cn.com/problems/single-number-ii",
        },
        {
          number: "260",
          name: "Single Number III",
          dis: "",
          link: "https://leetcode-cn.com/problems/single-number-iii",
        },
      ],
      //Topological Sort
      tableData113: [
        {
          number: "207",
          name: "Course Schedule",
          dis: "",
          link: "https://leetcode-cn.com/problems/course-schedule/description/",
        },
        {
          number: "210",
          name: "Course Schedule II",
          dis: "",
          link: "https://leetcode-cn.com/problems/course-schedule-ii/description/",
        },
        {
          number: "269",
          name: "Alien Dictionary",
          dis: "",
          link: "https://leetcode-cn.com/problems/alien-dictionary/description/",
        },
      ],
      //Random
      tableData114: [
        {
          number: "384",
          name: "Shuffle an Array",
          dis: "",
          link: "https://leetcode-cn.com/problems/shuffle-an-array",
        },
        {
          number: "398",
          name: "Random Pick Index",
          dis: "",
          link: "https://leetcode-cn.com/problems/random-pick-index",
        },
        {
          number: "382",
          name: "Linked List Random Node",
          dis: "",
          link: "https://leetcode-cn.com/problems/linked-list-random-node",
        },
        {
          number: "380",
          name: "Insert Delete GetRandom O(1)",
          dis: "",
          link: "https://leetcode-cn.com/problems/insert-delete-getrandom-o1",
        },
        {
          number: "381",
          name: "Insert Delete GetRandom O(1) - Duplicates allowed",
          dis: "",
          link: "https://leetcode-cn.com/problems/insert-delete-getrandom-o1-duplicates-allowed",
        },
        {
          number: "138",
          name: "Copy List with Random Pointer",
          dis: "",
          link: "https://leetcode-cn.com/problems/copy-list-with-random-pointer",
        },
      ],
      //Graph
      tableData115: [
        {
          number: "基础",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "133",
          name: "Clone Graph",
          dis: "",
          link: "https://leetcode-cn.com/problems/clone-graph",
        },
        {
          number: "399",
          name: "Evaluate Division",
          dis: "",
          link: "https://leetcode-cn.com/problems/evaluate-division",
        },
        {
          number: "310",
          name: "Minimum Height Trees",
          dis: "",
          link: "https://leetcode-cn.com/problems/minimum-height-trees",
        },
        {
          number: "图形学",
          name: "",
          dis: "",
          link: "",
        },
        {
          number: "149",
          name: "Max Points on a Line",
          dis: "",
          link: "https://leetcode-cn.com/problems/max-points-on-a-line",
        },
        {
          number: "335",
          name: "Self Crossing",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/self-crossing",
        },
        {
          number: "356",
          name: "Line Reflection",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/line-reflection",
        },
        {
          number: "391",
          name: "Perfect Rectangle",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/perfect-rectangle",
        },
        {
          number: "223",
          name: "Rectangle Area",
          dis: "很少考",
          link: "https://leetcode-cn.com/problems/rectangle-area",
        },
      ],
      //Union Find
      tableData116: [
        {
          number: "261",
          name: "Graph Valid Tree",
          dis: "",
          link: "https://leetcode-cn.com/problems/graph-valid-tree",
        },
        {
          number: "323",
          name: "Number of Connected Components in an Undirected Graph",
          dis: "",
          link: "https://leetcode-cn.com/problems/number-of-connected-components-in-an-undirected-graph",
        },
        {
          number: "305",
          name: "Number of Islands II",
          dis: "",
          link: "https://leetcode-cn.com/problems/number-of-islands-ii",
        },
      ],
      //Trie
      tableData117: [
        {
          number: "211",
          name: "Add and Search Word - Data structure design",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-add-and-search-words-data-structure",
        },
        {
          number: "208",
          name: "Implement Trie (Prefix Tree)",
          dis: "",
          link: "https://leetcode-cn.com/problems/implement-trie-prefix-tree",
        },
        {
          number: "212",
          name: "Word Search II",
          dis: "",
          link: "https://leetcode-cn.com/problems/word-search-ii",
        },
      ],
      //Design
      tableData118: [
        {
          number: "359",
          name: "Logger Rate Limiter",
          dis: "",
          link: "https://leetcode-cn.com/problems/logger-rate-limiter",
        },
        {
          number: "346",
          name: "Moving Average from Data Stream",
          dis: "Sliding Window",
          link: "https://leetcode-cn.com/problems/moving-average-from-data-stream",
        },
        {
          number: "362",
          name: "Design Hit Counter",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-hit-counter",
        },
        {
          number: "281",
          name: "Zigzag Iterator",
          dis: "",
          link: "https://leetcode-cn.com/problems/zigzag-iterator",
        },
        {
          number: "284",
          name: "Peeking Iterator",
          dis: "",
          link: "https://leetcode-cn.com/problems/peeking-iterator",
        },
        {
          number: "251",
          name: "Flatten 2D Vector",
          dis: "",
          link: "https://leetcode-cn.com/problems/flatten-2d-vector",
        },
        {
          number: "288",
          name: "Unique Word Abbreviation",
          dis: "",
          link: "https://leetcode-cn.com/problems/unique-word-abbreviation",
        },
        {
          number: "170",
          name: "Two Sum III - Data structure design",
          dis: "",
          link: "https://leetcode-cn.com/problems/two-sum-iii-data-structure-design",
        },
        {
          number: "348",
          name: "Design Tic-Tac-Toe",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-tic-tac-toe",
        },
        {
          number: "379",
          name: "Design Phone Directory",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-phone-directory",
        },
        {
          number: "353",
          name: "Design Snake Game",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-snake-game",
        },
        {
          number: "146",
          name: "LRU Cache",
          dis: "",
          link: "https://leetcode-cn.com/problems/lru-cache",
        },
        {
          number: "355",
          name: "Design Twitter",
          dis: "",
          link: "https://leetcode-cn.com/problems/design-twitter",
        },
        {
          number: "303",
          name: "Range Sum Query - Immutable",
          dis: "",
          link: "https://leetcode-cn.com/problems/range-sum-query-immutable",
        },
        {
          number: "304",
          name: "Range Sum Query 2D - Immutable",
          dis: "",
          link: "https://leetcode-cn.com/problems/range-sum-query-2d-immutable",
        },
        {
          number: "307",
          name: "Range Sum Query - Mutable",
          dis: "BIT & ST",
          link: "https://leetcode-cn.com/problems/range-sum-query-mutable",
        },
        {
          number: "308",
          name: "Range Sum Query 2D - Mutable",
          dis: "BIT & ST",
          link: "https://leetcode-cn.com/problems/range-sum-query-2d-mutable",
        },
      ],
      tableData22: [
        {
          number: "1",
          name: "Two Sum",
          link: "https://leetcode-cn.com/problems/two-sum",
        },
        {
          number: "3",
          name: "Longest Substring Without Repeating Characters",
          link: "https://leetcode-cn.com/problems/longest-substring-without-repeating-characters",
        },
        {
          number: "4",
          name: "Median of Two Sorted Arrays",
          link: "https://leetcode-cn.com/problems/median-of-two-sorted-arrays",
        },
        {
          number: "5",
          name: "Longest Palindromic Substring",
          link: "https://leetcode-cn.com/problems/longest-palindromic-substring",
        },
        {
          number: "7",
          name: "Reverse Integer",
          link: "https://leetcode-cn.com/problems/reverse-integer",
        },
        {
          number: "8",
          name: "String to Integer (atoi)",
          link: "https://leetcode-cn.com/problems/string-to-integer-atoi",
        },
        {
          number: "10",
          name: "Regular Expression Matching",
          link: "https://leetcode-cn.com/problems/regular-expression-matching",
        },
        {
          number: "11",
          name: "Container With Most Water",
          link: "https://leetcode-cn.com/problems/container-with-most-water",
        },
        {
          number: "12",
          name: "Integer to Roman",
          link: "https://leetcode-cn.com/problems/integer-to-roman",
        },
        {
          number: "13",
          name: "Roman to Integer",
          link: "https://leetcode-cn.com/problems/roman-to-integer",
        },
        {
          number: "15",
          name: "3Sum",
          link: "https://leetcode-cn.com/problems/3sum",
        },
        {
          number: "17",
          name: "Letter Combinations of a Phone Number",
          link: "https://leetcode-cn.com/problems/letter-combinations-of-a-phone-number",
        },
        {
          number: "18",
          name: "4Sum",
          link: "https://leetcode-cn.com/problems/4sum",
        },
        {
          number: "20",
          name: "Valid Parentheses",
          link: "https://leetcode-cn.com/problems/valid-parentheses",
        },
        {
          number: "22",
          name: "Generate Parentheses",
          link: "https://leetcode-cn.com/problems/generate-parentheses",
        },
        {
          number: "23",
          name: "Merge k Sorted Lists",
          link: "https://leetcode-cn.com/problems/merge-k-sorted-lists",
        },
        {
          number: "26",
          name: "Remove Duplicates from Sorted Array",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array",
        },
        {
          number: "27",
          name: "Remove Element",
          link: "https://leetcode-cn.com/problems/remove-element",
        },
        {
          number: "28",
          name: "Implement strStr()",
          link: "https://leetcode-cn.com/problems/implement-strstr",
        },
        {
          number: "29",
          name: "Divide Two Integers",
          link: "https://leetcode-cn.com/problems/divide-two-integers",
        },
        {
          number: "31",
          name: "Next Permutation",
          link: "https://leetcode-cn.com/problems/next-permutation",
        },
        {
          number: "32",
          name: "Longest Valid Parentheses",
          link: "https://leetcode-cn.com/problems/longest-valid-parentheses",
        },
        {
          number: "33",
          name: "Search in Rotated Sorted Array",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array",
        },
        {
          number: "34",
          name: "Find First and Last Position of Element in Sorted Array",
          link: "https://leetcode-cn.com/problems/find-first-and-last-position-of-element-in-sorted-array",
        },
        {
          number: "35",
          name: "Search Insert Position",
          link: "https://leetcode-cn.com/problems/search-insert-position",
        },
        {
          number: "36",
          name: "Valid Sudoku",
          link: "https://leetcode-cn.com/problems/valid-sudoku",
        },
        {
          number: "37",
          name: "Sudoku Solver",
          link: "https://leetcode-cn.com/problems/sudoku-solver",
        },
        {
          number: "38",
          name: "Count and Say",
          link: "https://leetcode-cn.com/problems/count-and-say",
        },
        {
          number: "39",
          name: "Combination Sum",
          link: "https://leetcode-cn.com/problems/combination-sum",
        },
        {
          number: "40",
          name: "Combination Sum II",
          link: "https://leetcode-cn.com/problems/combination-sum-ii",
        },
        {
          number: "41",
          name: "First Missing Positive",
          link: "https://leetcode-cn.com/problems/first-missing-positive",
        },
        {
          number: "42",
          name: "Trapping Rain Water",
          link: "https://leetcode-cn.com/problems/trapping-rain-water",
        },
        {
          number: "43",
          name: "Multiply Strings",
          link: "https://leetcode-cn.com/problems/multiply-strings",
        },
        {
          number: "44",
          name: "Wildcard Matching",
          link: "https://leetcode-cn.com/problems/wildcard-matching",
        },
        {
          number: "45",
          name: "Jump Game II",
          link: "https://leetcode-cn.com/problems/jump-game-ii",
        },
        {
          number: "46",
          name: "Permutations",
          link: "https://leetcode-cn.com/problems/permutations",
        },
        {
          number: "47",
          name: "Permutations II",
          link: "https://leetcode-cn.com/problems/permutations-ii",
        },
        {
          number: "48",
          name: "Rotate Image",
          link: "https://leetcode-cn.com/problems/rotate-image",
        },
        {
          number: "49",
          name: "Group Anagrams",
          link: "https://leetcode-cn.com/problems/group-anagrams",
        },
        {
          number: "50",
          name: "Pow(x, n)",
          link: "https://leetcode-cn.com/problems/powx-n",
        },
        {
          number: "51",
          name: "N-Queens",
          link: "https://leetcode-cn.com/problems/n-queens",
        },
        {
          number: "52",
          name: "N-Queens II",
          link: "https://leetcode-cn.com/problems/n-queens-ii",
        },
        {
          number: "53",
          name: "Maximum Subarray",
          link: "https://leetcode-cn.com/problems/maximum-subarray",
        },
        {
          number: "54",
          name: "Spiral Matrix",
          link: "https://leetcode-cn.com/problems/spiral-matrix",
        },
        {
          number: "55",
          name: "Jump Game",
          link: "https://leetcode-cn.com/problems/jump-game",
        },
        {
          number: "56",
          name: "Merge Intervals",
          link: "https://leetcode-cn.com/problems/merge-intervals",
        },
        {
          number: "57",
          name: "Insert Interval",
          link: "https://leetcode-cn.com/problems/insert-interval",
        },
        {
          number: "59",
          name: "Spiral Matrix II",
          link: "https://leetcode-cn.com/problems/spiral-matrix-ii/",
        },
        {
          number: "60",
          name: "Permutation Sequence",
          link: "https://leetcode-cn.com/problems/permutation-sequence",
        },
        {
          number: "62",
          name: "Unique Paths",
          link: "https://leetcode-cn.com/problems/unique-paths",
        },
        {
          number: "64",
          name: "Minimum Path Sum",
          link: "https://leetcode-cn.com/problems/minimum-path-sum",
        },
        {
          number: "65",
          name: "Valid Number",
          link: "https://leetcode-cn.com/problems/valid-number",
        },
        {
          number: "66",
          name: "Plus One",
          link: "https://leetcode-cn.com/problems/plus-one",
        },
        {
          number: "67",
          name: "Add Binary",
          link: "https://leetcode-cn.com/problems/add-binary",
        },
        {
          number: "68",
          name: "Text Justification",
          link: "https://leetcode-cn.com/problems/text-justification/description/",
        },
        {
          number: "69",
          name: "Sqrt(x)",
          link: "https://leetcode-cn.com/problems/sqrtx/description/",
        },
        {
          number: "70",
          name: "Climbing Stairs",
          link: "https://leetcode-cn.com/problems/climbing-stairs/description/",
        },
        {
          number: "71",
          name: "Simplify Path",
          link: "https://leetcode-cn.com/problems/simplify-path/description/",
        },
        {
          number: "72",
          name: "Edit Distance",
          link: "https://leetcode-cn.com/problems/edit-distance/description/",
        },
        {
          number: "74",
          name: "Search a 2D Matrix",
          link: "https://leetcode-cn.com/problems/search-a-2d-matrix/description/",
        },
        {
          number: "75",
          name: "Sort Colors",
          link: "https://leetcode-cn.com/problems/sort-colors/description/",
        },
        {
          number: "76",
          name: "Minimum Window Substring",
          link: "https://leetcode-cn.com/problems/minimum-window-substring/description/",
        },
        {
          number: "77",
          name: "Combinations",
          link: "https://leetcode-cn.com/problems/combinations/description/",
        },
        {
          number: "78",
          name: "Subsets",
          link: "https://leetcode-cn.com/problems/subsets/description/",
        },
        {
          number: "79",
          name: "AWord Search",
          link: "https://leetcode-cn.com/problems/word-search/description/",
        },
        {
          number: "80",
          name: "Remove Duplicates from Sorted Array II",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array-ii/description/",
        },
        {
          number: "81",
          name: "Search in Rotated Sorted Array II",
          link: "https://leetcode-cn.com/problems/search-in-rotated-sorted-array-ii/description/",
        },
        {
          number: "82",
          name: "Remove Duplicates from Sorted List II",
          link: "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-list-ii/description/",
        },
        {
          number: "84",
          name: "Largest Rectangle in Histogram",
          link: "https://leetcode-cn.com/problems/largest-rectangle-in-histogram/description/",
        },
        {
          number: "85",
          name: "Maximal Rectangle",
          link: "https://leetcode-cn.com/problems/maximal-rectangle/description/",
        },
        {
          number: "88",
          name: "Merge Sorted Array",
          link: "https://leetcode-cn.com/problems/merge-sorted-array/description/",
        },
        {
          number: "90",
          name: "Subsets II",
          link: "https://leetcode-cn.com/problems/subsets-ii/description/",
        },
        {
          number: "91",
          name: "Decode Ways",
          link: "https://leetcode-cn.com/problems/decode-ways/description/",
        },
        {
          number: "96",
          name: "Unique Binary Search Trees",
          link: "https://leetcode-cn.com/problems/unique-binary-search-trees/description/",
        },
        {
          number: "98",
          name: "Validate Binary Search Tree",
          link: "https://leetcode-cn.com/problems/validate-binary-search-tree/description/",
        },
        {
          number: "101",
          name: "Symmetric Tree",
          link: "https://leetcode-cn.com/problems/symmetric-tree/description/",
        },
        {
          number: "104",
          name: "Maximum Depth of Binary Tree",
          link: "https://leetcode-cn.com/problems/maximum-depth-of-binary-tree/description/",
        },
        {
          number: "108",
          name: "Convert Sorted Array to Binary Search Tree",
          link: "https://leetcode-cn.com/problems/convert-sorted-array-to-binary-search-tree/description/",
        },
        {
          number: "110",
          name: "	Balanced Binary Tree",
          link: "https://leetcode-cn.com/problems/balanced-binary-tree/description/",
        },
        {
          number: "111",
          name: "Minimum Depth of Binary Tree",
          link: "https://leetcode-cn.com/problems/minimum-depth-of-binary-tree/description/",
        },
        {
          number: "112",
          name: "Path Sum",
          link: "https://leetcode-cn.com/problems/path-sum/description/",
        },
        {
          number: "113",
          name: "Path Sum II",
          link: "https://leetcode-cn.com/problems/path-sum-ii/description/",
        },
        {
          number: "115",
          name: "Distinct Subsequences",
          link: "https://leetcode-cn.com/problems/distinct-subsequences/description/",
        },
        {
          number: "116",
          name: "Populating Next Right Pointers in Each Node",
          link: "https://leetcode-cn.com/problems/populating-next-right-pointers-in-each-node/description/",
        },
        {
          number: "117",
          name: "Populating Next Right Pointers in Each Node II",
          link: "https://leetcode-cn.com/problems/populating-next-right-pointers-in-each-node-ii/description/",
        },
        {
          number: "121",
          name: "Best Time to Buy and Sell Stock",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock/description/",
        },
        {
          number: "122",
          name: "Best Time to Buy and Sell Stock II",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-ii/description/",
        },
        {
          number: "123",
          name: "Best Time to Buy and Sell Stock III",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-iii/description/",
        },
        {
          number: "124",
          name: "Binary Tree Maximum Path Sum",
          link: "https://leetcode-cn.com/problems/binary-tree-maximum-path-sum/description/",
        },
        {
          number: "125",
          name: "Valid Palindrome",
          link: "https://leetcode-cn.com/problems/valid-palindrome/description/",
        },
        {
          number: "126",
          name: "Word Ladder II",
          link: "https://leetcode-cn.com/problems/word-ladder-ii/description/",
        },
        {
          number: "127",
          name: "Word Ladder",
          link: "https://leetcode-cn.com/problems/word-ladder/description/",
        },
        {
          number: "128",
          name: "Longest Consecutive Sequence",
          link: "https://leetcode-cn.com/problems/longest-consecutive-sequence/description/",
        },
        {
          number: "130",
          name: "Surrounded Regions",
          link: "https://leetcode-cn.com/problems/surrounded-regions/description/",
        },
        {
          number: "133",
          name: "Clone Graph",
          link: "https://leetcode-cn.com/problems/clone-graph/description/",
        },
        {
          number: "134",
          name: "Gas Station",
          link: "https://leetcode-cn.com/problems/gas-station/description/",
        },
        {
          number: "138",
          name: "Copy List with Random Pointer",
          link: "https://leetcode-cn.com/problems/copy-list-with-random-pointer/",
        },
        {
          number: "139",
          name: "Word Break",
          link: "https://leetcode-cn.com/problems/word-break/",
        },
        {
          number: "140",
          name: "Word Break II",
          link: "https://leetcode-cn.com/problems/word-break-ii/description/",
        },
        {
          number: "142",
          name: "Linked List Cycle II",
          link: "https://leetcode-cn.com/problems/linked-list-cycle-ii/description/",
        },
        {
          number: "146",
          name: "	LRU Cache",
          link: "https://leetcode-cn.com/problems/lru-cache/description/",
        },
        {
          number: "149",
          name: "Max Points on a Line",
          link: "https://leetcode-cn.com/problems/max-points-on-a-line/description/",
        },
        {
          number: "150",
          name: "Evaluate Reverse Polish Notation",
          link: "https://leetcode-cn.com/problems/evaluate-reverse-polish-notation/description/",
        },
        {
          number: "152",
          name: "Maximum Product Subarray",
          link: "https://leetcode-cn.com/problems/maximum-product-subarray/description/",
        },
        {
          number: "153",
          name: "Find Minimum in Rotated Sorted Array",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array/description/",
        },
        {
          number: "154",
          name: "Find Minimum in Rotated Sorted Array II",
          link: "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array-ii/description/",
        },
        {
          number: "155",
          name: "Min Stack",
          link: "https://leetcode-cn.com/problems/min-stack/description/",
        },
        {
          number: "157",
          name: "Read N Characters Given Read4",
          link: "https://leetcode-cn.com/problems/read-n-characters-given-read4/description/",
        },
        {
          number: "158",
          name: "Read N Characters Given Read4 II - Call multiple times",
          link: "https://leetcode-cn.com/problems/read-n-characters-given-read4-ii-call-multiple-times/description/",
        },
        {
          number: "161",
          name: "One Edit Distance",
          link: "https://leetcode-cn.com/problems/one-edit-distance/",
        },
        {
          number: "162",
          name: "Find Peak Element",
          link: "https://leetcode-cn.com/problems/find-peak-element/description/",
        },
        {
          number: "163",
          name: "Missing Ranges",
          link: "https://leetcode-cn.com/problems/missing-ranges/description/",
        },
        {
          number: "168",
          name: "Excel Sheet Column Title",
          link: "https://leetcode-cn.com/problems/excel-sheet-column-title/description/",
        },
        {
          number: "171",
          name: "Excel Sheet Column Number",
          link: "https://leetcode-cn.com/problems/excel-sheet-column-number/description/",
        },
        {
          number: "173",
          name: "Binary Search Tree Iterator",
          link: "https://leetcode-cn.com/problems/binary-search-tree-iterator/description/",
        },
        {
          number: "174",
          name: "Dungeon Game",
          link: "https://leetcode-cn.com/problems/dungeon-game/description/",
        },
        {
          number: "186",
          name: "Reverse Words in a String II",
          link: "https://leetcode-cn.com/problems/reverse-words-in-a-string-ii/description/",
        },
        {
          number: "188",
          name: "Best Time to Buy and Sell Stock IV",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-iv/description/",
        },
        {
          number: "189",
          name: "Rotate Array",
          link: "https://leetcode-cn.com/problems/rotate-array/description/",
        },
        {
          number: "191",
          name: "Number of 1 Bits",
          link: "https://leetcode-cn.com/problems/number-of-1-bits/description/",
        },
        {
          number: "198",
          name: "House Robber",
          link: "https://leetcode-cn.com/problems/house-robber/",
        },
        {
          number: "200",
          name: "Number of Islands",
          link: "https://leetcode-cn.com/problems/number-of-islands/",
        },
        {
          number: "201",
          name: "Bitwise AND of Numbers Range",
          link: "https://leetcode-cn.com/problems/bitwise-and-of-numbers-range/description/",
        },
        {
          number: "202",
          name: "Happy Number",
          link: "https://leetcode-cn.com/problems/happy-number/description/",
        },
        {
          number: "204",
          name: "Count Primes",
          link: "https://leetcode-cn.com/problems/count-primes/description/",
        },
        {
          number: "205",
          name: "Isomorphic Strings",
          link: "https://leetcode-cn.com/problems/isomorphic-strings/description/",
        },
        {
          number: "207",
          name: "Course Schedule",
          link: "https://leetcode-cn.com/problems/course-schedule/description/",
        },
        {
          number: "208",
          name: "Implement Trie (Prefix Tree)",
          link: "https://leetcode-cn.com/problems/implement-trie-prefix-tree/description/",
        },
        {
          number: "209",
          name: "Minimum Size Subarray Sum",
          link: "https://leetcode-cn.com/problems/minimum-size-subarray-sum/description/",
        },
        {
          number: "210",
          name: "Course Schedule II",
          link: "https://leetcode-cn.com/problems/course-schedule-ii/description/",
        },
        {
          number: "211",
          name: "Add and Search Word - Data structure design",
          link: "https://leetcode-cn.com/problems/add-and-search-word-data-structure-design/description/",
        },
        {
          number: "212",
          name: "Word Search II",
          link: "https://leetcode-cn.com/problems/word-search-ii/description/",
        },
        {
          number: "213",
          name: "House Robber II",
          link: "https://leetcode-cn.com/problems/house-robber-ii/",
        },
        {
          number: "214",
          name: "Shortest Palindrome",
          link: "https://leetcode-cn.com/problems/shortest-palindrome/description/",
        },
        {
          number: "215",
          name: "Kth Largest Element in an Array",
          link: "https://leetcode-cn.com/problems/kth-largest-element-in-an-array/description/",
        },
        {
          number: "216",
          name: "Combination Sum III",
          link: "https://leetcode-cn.com/problems/combination-sum-iii/description/",
        },
        {
          number: "217",
          name: "Contains Duplicate",
          link: "https://leetcode-cn.com/problems/contains-duplicate/description/",
        },
        {
          number: "218",
          name: "The Skyline Problem",
          link: "https://leetcode-cn.com/problems/the-skyline-problem/description/",
        },
        {
          number: "219",
          name: "Contains Duplicate II",
          link: "https://leetcode-cn.com/problems/contains-duplicate-ii/description/",
        },
        {
          number: "220",
          name: "Contains Duplicate III",
          link: "https://leetcode-cn.com/problems/contains-duplicate-iii/description/",
        },
        {
          number: "221",
          name: "Maximal Square",
          link: "https://leetcode-cn.com/problems/maximal-square/description/",
        },
        {
          number: "224",
          name: "Basic Calculator",
          link: "https://leetcode-cn.com/problems/basic-calculator/description/",
        },
        {
          number: "225",
          name: "Implement Stack using Queues",
          link: "https://leetcode-cn.com/problems/implement-stack-using-queues/description/",
        },
        {
          number: "226",
          name: "Invert Binary Tree",
          link: "https://leetcode-cn.com/problems/invert-binary-tree/description/",
        },
        {
          number: "227",
          name: "Basic Calculator II",
          link: "https://leetcode-cn.com/problems/basic-calculator-ii/description/",
        },
        {
          number: "228",
          name: "Summary Ranges",
          link: "https://leetcode-cn.com/problems/summary-ranges/description/",
        },
        {
          number: "230",
          name: "Kth Smallest Element in a BST",
          link: "https://leetcode-cn.com/problems/kth-smallest-element-in-a-bst/description/",
        },
        {
          number: "231",
          name: "Power of Two",
          link: "https://leetcode-cn.com/problems/power-of-two/description/",
        },
        {
          number: "232",
          name: "Implement Queue using Stacks",
          link: "https://leetcode-cn.com/problems/implement-queue-using-stacks/description/",
        },
        {
          number: "235",
          name: "Lowest Common Ancestor of a Binary Search Tree",
          link: "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-search-tree/description/",
        },
        {
          number: "236",
          name: "Lowest Common Ancestor of a Binary Tree",
          link: "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-tree/description/",
        },
        {
          number: "238",
          name: "Product of Array Except Self",
          link: "https://leetcode-cn.com/problems/product-of-array-except-self/description/",
        },
        {
          number: "239",
          name: "Sliding Window Maximum",
          link: "https://leetcode-cn.com/problems/sliding-window-maximum/description/",
        },
        {
          number: "240",
          name: "Search a 2D Matrix II",
          link: "https://leetcode-cn.com/problems/search-a-2d-matrix-ii/description/",
        },
        {
          number: "241",
          name: "Different Ways to Add Parentheses",
          link: "https://leetcode-cn.com/problems/different-ways-to-add-parentheses/description/",
        },
        {
          number: "242",
          name: "Valid Anagram",
          link: "https://leetcode-cn.com/problems/valid-anagram/description/",
        },
        {
          number: "244",
          name: "Shortest Word Distance II",
          link: "https://leetcode-cn.com/problems/shortest-word-distance-ii/description/",
        },
        {
          number: "245",
          name: "Shortest Word Distance III",
          link: "https://leetcode-cn.com/problems/shortest-word-distance-iii/description/",
        },
        {
          number: "249",
          name: "Group Shifted Strings",
          link: "https://leetcode-cn.com/problems/group-shifted-strings/description/",
        },
        {
          number: "251",
          name: "Flatten 2D Vector",
          link: "https://leetcode-cn.com/problems/flatten-2d-vector/description/s",
        },
        {
          number: "252",
          name: "Meeting Rooms",
          link: "https://leetcode-cn.com/problems/meeting-rooms/description/",
        },
        {
          number: "253",
          name: "Meeting Rooms II",
          link: "https://leetcode-cn.com/problems/meeting-rooms-ii/description/",
        },
        {
          number: "254",
          name: "Factor Combinations",
          link: "https://leetcode-cn.com/problems/factor-combinations/description/",
        },
        {
          number: "256",
          name: "Paint House",
          link: "https://leetcode-cn.com/problems/paint-house/description/",
        },
        {
          number: "257",
          name: "Binary Tree Paths",
          link: "https://leetcode-cn.com/problems/binary-tree-paths/description/",
        },
        {
          number: "261",
          name: "Graph Valid Tree",
          link: "https://leetcode-cn.com/problems/graph-valid-tree/description/",
        },
        {
          number: "263",
          name: "Ugly Number",
          link: "https://leetcode-cn.com/problems/ugly-number/description/",
        },
        {
          number: "264",
          name: "Ugly Number II",
          link: "https://leetcode-cn.com/problems/ugly-number-ii/description/",
        },
        {
          number: "265",
          name: "Paint House II",
          link: "https://leetcode-cn.com/problems/paint-house-ii/description/",
        },
        {
          number: "268",
          name: "Missing Number",
          link: "https://leetcode-cn.com/problems/missing-number/description/",
        },
        {
          number: "269",
          name: "Alien Dictionary",
          link: "https://leetcode-cn.com/problems/alien-dictionary/description/",
        },
        {
          number: "270",
          name: "Closest Binary Search Tree Value",
          link: "https://leetcode-cn.com/problems/closest-binary-search-tree-value/description/",
        },
        {
          number: "271",
          name: "Encode and Decode Strings",
          link: "https://leetcode-cn.com/problems/encode-and-decode-strings/description/",
        },
        {
          number: "273",
          name: "Integer to English Words",
          link: "https://leetcode-cn.com/problems/integer-to-english-words/description/",
        },
        {
          number: "274",
          name: "H-Index",
          link: "https://leetcode-cn.com/problems/h-index/description/",
        },
        {
          number: "275",
          name: "H-Index II",
          link: "https://leetcode-cn.com/problems/h-index-ii/description/",
        },
        {
          number: "276",
          name: "Paint Fence",
          link: "https://leetcode-cn.com/problems/paint-fence/description/",
        },
        {
          number: "277",
          name: "Find the Celebrity",
          link: "https://leetcode-cn.com/problems/find-the-celebrity/description/",
        },
        {
          number: "278",
          name: "First Bad Version",
          link: "https://leetcode-cn.com/problems/first-bad-version/description/",
        },
        {
          number: "279",
          name: "Perfect Squares",
          link: "https://leetcode-cn.com/problems/perfect-squares/description/",
        },
        {
          number: "280",
          name: "Wiggle Sort",
          link: "https://leetcode-cn.com/problems/wiggle-sort/description/",
        },
        {
          number: "282",
          name: "Expression Add Operators",
          link: "https://leetcode-cn.com/problems/expression-add-operators/description/",
        },
        {
          number: "283",
          name: "Move Zeroes",
          link: "https://leetcode-cn.com/problems/move-zeroes/description/",
        },
        {
          number: "284",
          name: "Peeking Iterator",
          link: "https://leetcode-cn.com/problems/peeking-iterator/description/",
        },
        {
          number: "285",
          name: "Inorder Successor in BST",
          link: "https://leetcode-cn.com/problems/inorder-successor-in-bst/description/",
        },
        {
          number: "286",
          name: "Walls and Gates",
          link: "https://leetcode-cn.com/problems/walls-and-gates/description/",
        },
        {
          number: "287",
          name: "Find the Duplicate Number",
          link: "https://leetcode-cn.com/problems/find-the-duplicate-number/description/",
        },
        {
          number: "288",
          name: "Unique Word Abbreviation",
          link: "https://leetcode-cn.com/problems/unique-word-abbreviation/description/",
        },
        {
          number: "289",
          name: "Game of Life",
          link: "https://leetcode-cn.com/problems/game-of-life/description/",
        },
        {
          number: "290",
          name: "Word Pattern",
          link: "https://leetcode-cn.com/problems/word-pattern/description/",
        },
        {
          number: "291",
          name: "Word Pattern II",
          link: "https://leetcode-cn.com/problems/word-pattern-ii/description/",
        },
        {
          number: "293",
          name: "Flip Game",
          link: "https://leetcode-cn.com/problems/flip-game/description/",
        },
        {
          number: "294",
          name: "Flip Game II",
          link: "https://leetcode-cn.com/problems/flip-game-ii/description/",
        },
        {
          number: "295",
          name: "Find Median from Data Stream",
          link: "https://leetcode-cn.com/problems/find-median-from-data-stream/description/",
        },
        {
          number: "296",
          name: "Best Meeting Point",
          link: "https://leetcode-cn.com/problems/best-meeting-point/description/",
        },
        {
          number: "297",
          name: "Serialize and Deserialize Binary Tree",
          link: "https://leetcode-cn.com/problems/serialize-and-deserialize-binary-tree/description/",
        },
        {
          number: "298",
          name: "Binary Tree Longest Consecutive Sequence",
          link: "https://leetcode-cn.com/problems/binary-tree-longest-consecutive-sequence/description/",
        },
        {
          number: "299",
          name: "Bulls and Cows",
          link: "https://leetcode-cn.com/problems/bulls-and-cows/",
        },
        {
          number: "300",
          name: "Longest Increasing Subsequence",
          link: "https://leetcode-cn.com/problems/longest-increasing-subsequence/description/",
        },
        {
          number: "301",
          name: "Remove Invalid Parentheses",
          link: "https://leetcode-cn.com/problems/remove-invalid-parentheses/description/",
        },
        {
          number: "302",
          name: "Smallest Rectangle Enclosing Black Pixels",
          link: "https://leetcode-cn.com/problems/smallest-rectangle-enclosing-black-pixels/description/",
        },
        {
          number: "305",
          name: "Number of Islands II",
          link: "https://leetcode-cn.com/problems/number-of-islands-ii/description/",
        },
        {
          number: "307",
          name: "Range Sum Query - Mutable",
          link: "https://leetcode-cn.com/problems/range-sum-query-mutable/description/",
        },
        {
          number: "308",
          name: "Range Sum Query 2D - Mutable",
          link: "https://leetcode-cn.com/problems/range-sum-query-2d-mutable/description/",
        },
        {
          number: "309",
          name: "Best Time to Buy and Sell Stock with Cooldown",
          link: "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-with-cooldown/description/",
        },
        {
          number: "311",
          name: "Sparse Matrix Multiplication",
          link: "https://leetcode-cn.com/problems/sparse-matrix-multiplication/description/",
        },
        {
          number: "312",
          name: "Burst Balloons",
          link: "https://leetcode-cn.com/problems/burst-balloons/description/",
        },
        {
          number: "314",
          name: "Binary Tree Vertical Order Traversal",
          link: "https://leetcode-cn.com/problems/binary-tree-vertical-order-traversal/description/",
        },
        {
          number: "316",
          name: "Remove Duplicate Letters",
          link: "https://leetcode-cn.com/problems/remove-duplicate-letters/description/",
        },
        {
          number: "317",
          name: "Shortest Distance from All Buildings",
          link: "https://leetcode-cn.com/problems/shortest-distance-from-all-buildings/description/",
        },
        {
          number: "318",
          name: "Maximum Product of Word Lengths",
          link: "https://leetcode-cn.com/problems/maximum-product-of-word-lengths/description/",
        },
        {
          number: "322",
          name: "Coin Change",
          link: "https://leetcode-cn.com/problems/coin-change/description/",
        },
        {
          number: "323",
          name: "Number of Connected Components in an Undirected Graph",
          link: "https://leetcode-cn.com/problems/number-of-connected-components-in-an-undirected-graph/description/",
        },
        {
          number: "324",
          name: "Wiggle Sort II",
          link: "https://leetcode-cn.com/problems/wiggle-sort-ii/description/",
        },
        {
          number: "325",
          name: "Maximum Size Subarray Sum Equals k",
          link: "https://leetcode-cn.com/problems/maximum-size-subarray-sum-equals-k/",
        },
        {
          number: "329",
          name: "Longest Increasing Path in a Matrix",
          link: "https://leetcode-cn.com/problems/longest-increasing-path-in-a-matrix/description/",
        },
        {
          number: "334",
          name: "Increasing Triplet Subsequence",
          link: "https://leetcode-cn.com/problems/increasing-triplet-subsequence/description/",
        },
        {
          number: "336",
          name: "Palindrome Pairs",
          link: "https://leetcode-cn.com/problems/palindrome-pairs/description/",
        },
        {
          number: "337",
          name: "House Robber III",
          link: "https://leetcode-cn.com/problems/house-robber-iii/description/",
        },
        {
          number: "338",
          name: "Counting Bits",
          link: "https://leetcode-cn.com/problems/counting-bits/description/",
        },
        {
          number: "339",
          name: "Nested List Weight Sum",
          link: "https://leetcode-cn.com/problems/nested-list-weight-sum/description/",
        },
        {
          number: "340",
          name: "Longest Substring with At Most K Distinct Characters",
          link: "https://leetcode-cn.com/problems/longest-substring-with-at-most-k-distinct-characters/description/",
        },
        {
          number: "341",
          name: "Flatten Nested List Iterator",
          link: "https://leetcode-cn.com/problems/flatten-nested-list-iterator/",
        },
        {
          number: "346",
          name: "Moving Average from Data Stream",
          link: "https://leetcode-cn.com/problems/moving-average-from-data-stream/description/",
        },
        {
          number: "347",
          name: "Top K Frequent Elements",
          link: "https://leetcode-cn.com/problems/top-k-frequent-elements/description/",
        },
        {
          number: "348",
          name: "Design Tic-Tac-Toe",
          link: "https://leetcode-cn.com/problems/design-tic-tac-toe/description/",
        },
        {
          number: "350",
          name: "Intersection of Two Arrays II",
          link: "https://leetcode-cn.com/problems/intersection-of-two-arrays-ii/description/",
        },
        {
          number: "351",
          name: "Android Unlock Patterns",
          link: "https://leetcode-cn.com/problems/android-unlock-patterns/description/",
        },
        {
          number: "352",
          name: "Data Stream as Disjoint Intervals",
          link: "https://leetcode-cn.com/problems/data-stream-as-disjoint-intervals/description/",
        },
        {
          number: "353",
          name: "Design Snake Game",
          link: "https://leetcode-cn.com/problems/design-snake-game/description/",
        },
        {
          number: "354",
          name: "Russian Doll Envelopes",
          link: "https://leetcode-cn.com/problems/russian-doll-envelopes/description/",
        },
        {
          number: "355",
          name: "Design Twitter",
          link: "https://leetcode-cn.com/problems/design-twitter/description/s",
        },
        {
          number: "359",
          name: "Logger Rate Limiter",
          link: "https://leetcode-cn.com/problems/logger-rate-limiter/description/",
        },
        {
          number: "361",
          name: "Bomb Enemy",
          link: "https://leetcode-cn.com/problems/bomb-enemy/description/",
        },
        {
          number: "362",
          name: "Design Hit Counter",
          link: "https://leetcode-cn.com/problems/design-hit-counter/description/",
        },
        {
          number: "364",
          name: "Nested List Weight Sum II",
          link: "https://leetcode-cn.com/problems/nested-list-weight-sum-ii/description/",
        },
        {
          number: "367",
          name: "Valid Perfect Square",
          link: "https://leetcode-cn.com/problems/valid-perfect-square/description/",
        },
        {
          number: "374",
          name: "Guess Number Higher or Lower",
          link: "https://leetcode-cn.com/problems/guess-number-higher-or-lower/",
        },
        {
          number: "375",
          name: "Guess Number Higher or Lower II",
          link: "https://leetcode-cn.com/problems/guess-number-higher-or-lower-ii/description/",
        },
        {
          number: "376",
          name: "Wiggle Subsequence",
          link: "https://leetcode-cn.com/problems/wiggle-subsequence/description/",
        },
        {
          number: "377",
          name: "Combination Sum IV",
          link: "https://leetcode-cn.com/problems/combination-sum-iv/description/",
        },
        {
          number: "378",
          name: "Kth Smallest Element in a Sorted Matrix",
          link: "https://leetcode-cn.com/problems/kth-smallest-element-in-a-sorted-matrix/description/",
        },
        {
          number: "379",
          name: "Design Phone Directory",
          link: "https://leetcode-cn.com/problems/design-phone-directory/description/",
        },
        {
          number: "380",
          name: "Insert Delete GetRandom O(1)",
          link: "https://leetcode-cn.com/problems/insert-delete-getrandom-o1/",
        },
        {
          number: "381",
          name: "Insert Delete GetRandom O(1) - Duplicates allowed",
          link: "https://leetcode-cn.com/problems/insert-delete-getrandom-o1-duplicates-allowed/",
        },
        {
          number: "384",
          name: "Shuffle an Array",
          link: "https://leetcode-cn.com/problems/shuffle-an-array/",
        },
        {
          number: "385",
          name: "Mini Parser",
          link: "https://leetcode-cn.com/problems/mini-parser/description/",
        },
        {
          number: "389",
          name: "Find the Difference",
          link: "https://leetcode-cn.com/problems/find-the-difference/description/",
        },
        {
          number: "394",
          name: "Decode String",
          link: "https://leetcode-cn.com/problems/decode-string/",
        },
        {
          number: "398",
          name: "Random Pick Index",
          link: "https://leetcode-cn.com/problems/random-pick-index/",
        },
      ],
      tableData32: [
        {
          number: "1",
          name: "Two Sum",
          linke: "https://leetcode-cn.com/problems/two-sum",
        },
        {
          number: "2",
          name: "Add Two Numbers",
          linke: "https://leetcode-cn.com/problems/add-two-numbers",
        },
        {
          number: "3",
          name: "Longest Substring Without Repeating Characters",
          linke:
            "https://leetcode-cn.com/problems/longest-substring-without-repeating-characters",
        },
        {
          number: "5",
          name: "Longest Palindromic Substring",
          linke:
            "https://leetcode-cn.com/problems/longest-palindromic-substring",
        },
        {
          number: "7",
          name: "Reverse Integer",
          linke: "https://leetcode-cn.com/problems/reverse-integer",
        },
        {
          number: "8",
          name: "String to Integer (atoi)",
          linke: "https://leetcode-cn.com/problems/string-to-integer-atoi",
        },
        {
          number: "9",
          name: "Palindrome Number",
          linke: "https://leetcode-cn.com/problems/palindrome-number",
        },
        {
          number: "11",
          name: "Container With Most Water",
          linke: "https://leetcode-cn.com/problems/container-with-most-water",
        },
        {
          number: "14",
          name: "Longest Common Prefix",
          linke: "https://leetcode-cn.com/problems/longest-common-prefix",
        },
        {
          number: "15",
          name: "3Sum",
          linke: "https://leetcode-cn.com/problems/3sum",
        },
        {
          number: "16",
          name: "3Sum Closest",
          linke: "https://leetcode-cn.com/problems/3sum-closest",
        },
        {
          number: "17",
          name: "Letter Combinations of a Phone Number",
          linke:
            "https://leetcode-cn.com/problems/letter-combinations-of-a-phone-number",
        },
        {
          number: "18",
          name: "4Sum",
          linke: "https://leetcode-cn.com/problems/4sum",
        },
        {
          number: "19",
          name: "Remove Nth Node From End of List",
          linke:
            "https://leetcode-cn.com/problems/remove-nth-node-from-end-of-list",
        },
        {
          number: "20",
          name: "Valid Parentheses",
          linke: "https://leetcode-cn.com/problems/valid-parentheses",
        },
        {
          number: "21",
          name: "Merge Two Sorted Lists",
          linke: "https://leetcode-cn.com/problems/merge-two-sorted-lists",
        },
        {
          number: "22",
          name: "Generate Parentheses",
          linke: "https://leetcode-cn.com/problems/generate-parentheses",
        },
        {
          number: "24",
          name: "Swap Nodes in Pairs",
          linke: "https://leetcode-cn.com/problems/swap-nodes-in-pairs",
        },
        {
          number: "25",
          name: "Reverse Nodes in k-Group",
          linke: "https://leetcode-cn.com/problems/reverse-nodes-in-k-group",
        },
        {
          number: "26",
          name: "Remove Duplicates from Sorted Array",
          linke:
            "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array",
        },
        {
          number: "27",
          name: "Remove Element",
          linke: "https://leetcode-cn.com/problems/remove-element",
        },
        {
          number: "28",
          name: "Implement strStr()",
          linke: "https://leetcode-cn.com/problems/implement-strstr",
        },
        {
          number: "29",
          name: "Divide Two Integers",
          linke: "https://leetcode-cn.com/problems/divide-two-integers",
        },
        {
          number: "30",
          name: "Substring with Concatenation of All Words",
          linke:
            "https://leetcode-cn.com/problems/substring-with-concatenation-of-all-words",
        },
        {
          number: "32",
          name: "Longest Valid Parentheses",
          linke: "https://leetcode-cn.com/problems/longest-valid-parentheses",
        },
        {
          number: "33",
          name: "Search in Rotated Sorted Array",
          linke:
            "https://leetcode-cn.com/problems/search-in-rotated-sorted-array",
        },
        {
          number: "35",
          name: "Search Insert Position",
          linke: "https://leetcode-cn.com/problems/search-insert-position",
        },
        {
          number: "36",
          name: "Valid Sudoku",
          linke: "https://leetcode-cn.com/problems/valid-sudoku",
        },
        {
          number: "39",
          name: "Combination Sum",
          linke: "https://leetcode-cn.com/problems/combination-sum",
        },
        {
          number: "40",
          name: "Combination Sum II",
          linke: "https://leetcode-cn.com/problems/combination-sum-ii",
        },
        {
          number: "41",
          name: "First Missing Positive",
          linke: "https://leetcode-cn.com/problems/first-missing-positive",
        },
        {
          number: "42",
          name: "Trapping Rain Water",
          linke: "https://leetcode-cn.com/problems/trapping-rain-water",
        },
        {
          number: "45",
          name: "Jump Game II",
          linke: "https://leetcode-cn.com/problems/jump-game-ii",
        },
        {
          number: "46",
          name: "Permutations",
          linke: "https://leetcode-cn.com/problems/permutations",
        },
        {
          number: "47",
          name: "Permutations II",
          linke: "https://leetcode-cn.com/problems/permutations-ii",
        },
        {
          number: "49",
          name: "Group Anagrams",
          linke: "https://leetcode-cn.com/problems/group-anagrams",
        },
        {
          number: "50",
          name: "Pow(x, n)",
          linke: "https://leetcode-cn.com/problems/powx-n",
        },
        {
          number: "53",
          name: "Maximum Subarray",
          linke: "https://leetcode-cn.com/problems/maximum-subarray",
        },
        {
          number: "55",
          name: "Jump Game",
          linke: "https://leetcode-cn.com/problems/jump-game",
        },
        {
          number: "56",
          name: "Merge Intervals",
          linke: "https://leetcode-cn.com/problems/merge-intervals",
        },
        {
          number: "57",
          name: "Insert Interval",
          linke: "https://leetcode-cn.com/problems/insert-interval",
        },
        {
          number: "58",
          name: "Length of Last Word",
          linke: "https://leetcode-cn.com/problems/length-of-last-word",
        },
        {
          number: "60",
          name: "Permutation Sequence",
          linke: "https://leetcode-cn.com/problems/permutation-sequence",
        },
        {
          number: "61",
          name: "Rotate List",
          linke: "https://leetcode-cn.com/problems/rotate-list",
        },
        {
          number: "62",
          name: "Unique Paths",
          linke: "https://leetcode-cn.com/problems/unique-paths",
        },
        {
          number: "63",
          name: "Unique Paths II",
          linke: "https://leetcode-cn.com/problems/unique-paths-ii",
        },
        {
          number: "64",
          name: "Minimum Path Sum",
          linke: "https://leetcode-cn.com/problems/minimum-path-sum",
        },
        {
          number: "66",
          name: "Plus One",
          linke: "https://leetcode-cn.com/problems/plus-one",
        },
        {
          number: "69",
          name: "Sqrt(x)",
          linke: "https://leetcode-cn.com/problems/sqrtx",
        },
        {
          number: "70",
          name: "Climbing Stairs",
          linke: "https://leetcode-cn.com/problems/climbing-stairs",
        },
        {
          number: "71",
          name: "Simplify Path",
          linke: "https://leetcode-cn.com/problems/simplify-path",
        },
        {
          number: "74",
          name: "Search a 2D Matrix",
          linke: "https://leetcode-cn.com/problems/search-a-2d-matrix",
        },
        {
          number: "75",
          name: "Sort Colors",
          linke: "https://leetcode-cn.com/problems/sort-colors",
        },
        {
          number: "76",
          name: "Minimum Window Substring",
          linke: "https://leetcode-cn.com/problems/minimum-window-substring",
        },
        {
          number: "77",
          name: "Combinations",
          linke: "https://leetcode-cn.com/problems/combinations",
        },
        {
          number: "78",
          name: "Subsets",
          linke: "https://leetcode-cn.com/problems/subsets",
        },
        {
          number: "79",
          name: "Word Search",
          linke: "https://leetcode-cn.com/problems/word-search",
        },
        {
          number: "80",
          name: "Remove Duplicates from Sorted Array II",
          linke:
            "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-array-ii",
        },
        {
          number: "82",
          name: "Remove Duplicates from Sorted List II",
          linke:
            "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-list-ii",
        },
        {
          number: "83",
          name: "Remove Duplicates from Sorted List",
          linke:
            "https://leetcode-cn.com/problems/remove-duplicates-from-sorted-list",
        },
        {
          number: "86",
          name: "Partition List",
          linke: "https://leetcode-cn.com/problems/partition-list",
        },
        {
          number: "87",
          name: "Scramble String",
          linke: "https://leetcode-cn.com/problems/scramble-string",
        },
        {
          number: "88",
          name: "Merge Sorted Array",
          linke: "https://leetcode-cn.com/problems/merge-sorted-array",
        },
        {
          number: "90",
          name: "Subsets II",
          linke: "https://leetcode-cn.com/problems/subsets-ii",
        },
        {
          number: "92",
          name: "Reverse Linked List II",
          linke: "https://leetcode-cn.com/problems/reverse-linked-list-ii",
        },
        {
          number: "94",
          name: "Binary Tree Inorder Traversal",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-inorder-traversal",
        },
        {
          number: "98",
          name: "Validate Binary Search Tree",
          linke: "https://leetcode-cn.com/problems/validate-binary-search-tree",
        },
        {
          number: "99",
          name: "Recover Binary Search Tree",
          linke: "https://leetcode-cn.com/problems/recover-binary-search-tree",
        },
        {
          number: "100",
          name: "Same Tree",
          linke: "https://leetcode-cn.com/problems/same-tree",
        },
        {
          number: "101",
          name: "Symmetric Tree",
          linke: "https://leetcode-cn.com/problems/symmetric-tree",
        },
        {
          number: "102",
          name: "Binary Tree Level Order Traversal",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-level-order-traversal",
        },
        {
          number: "103",
          name: "Binary Tree Zigzag Level Order Traversal",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-zigzag-level-order-traversal",
        },
        {
          number: "104",
          name: "Maximum Depth of Binary Tree",
          linke:
            "https://leetcode-cn.com/problems/maximum-depth-of-binary-tree",
        },
        {
          number: "107",
          name: "Binary Tree Level Order Traversal II",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-level-order-traversal-ii",
        },
        {
          number: "110",
          name: "Balanced Binary Tree",
          linke: "https://leetcode-cn.com/problems/balanced-binary-tree",
        },
        {
          number: "111",
          name: "Minimum Depth of Binary Tree",
          linke:
            "https://leetcode-cn.com/problems/minimum-depth-of-binary-tree",
        },
        {
          number: "112",
          name: "Path Sum",
          linke: "https://leetcode-cn.com/problems/path-sum",
        },
        {
          number: "113",
          name: "Path Sum II",
          linke: "https://leetcode-cn.com/problems/path-sum-ii",
        },
        {
          number: "121",
          name: "Best Time to Buy and Sell Stock",
          linke:
            "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock",
        },
        {
          number: "122",
          name: "Best Time to Buy and Sell Stock II",
          linke:
            "https://leetcode-cn.com/problems/best-time-to-buy-and-sell-stock-ii",
        },
        {
          number: "124",
          name: "Binary Tree Maximum Path Sum",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-maximum-path-sum",
        },
        {
          number: "125",
          name: "Valid Palindrome",
          linke: "https://leetcode-cn.com/problems/valid-palindrome",
        },
        {
          number: "127",
          name: "Word Ladder",
          linke: "https://leetcode-cn.com/problems/word-ladder",
        },
        {
          number: "129",
          name: "Sum Root to Leaf Numbers",
          linke: "https://leetcode-cn.com/problems/sum-root-to-leaf-numbers",
        },
        {
          number: "131",
          name: "Palindrome Partitioning",
          linke: "https://leetcode-cn.com/problems/palindrome-partitioning",
        },
        {
          number: "136",
          name: "Single Number",
          linke: "https://leetcode-cn.com/problems/single-number",
        },
        {
          number: "138",
          name: "Copy List with Random Pointer",
          linke:
            "https://leetcode-cn.com/problems/copy-list-with-random-pointer",
        },
        {
          number: "139",
          name: "Word Break",
          linke: "https://leetcode-cn.com/problems/word-break",
        },
        {
          number: "141",
          name: "Linked List Cycle",
          linke: "https://leetcode-cn.com/problems/linked-list-cycle",
        },
        {
          number: "142",
          name: "Linked List Cycle II",
          linke: "https://leetcode-cn.com/problems/linked-list-cycle-ii",
        },
        {
          number: "143",
          name: "Reorder List",
          linke: "https://leetcode-cn.com/problems/reorder-list",
        },
        {
          number: "144",
          name: "Binary Tree Preorder Traversal",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-preorder-traversal",
        },
        {
          number: "145",
          name: "Binary Tree Postorder Traversal",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-postorder-traversal",
        },
        {
          number: "148",
          name: "Sort List",
          linke: "https://leetcode-cn.com/problems/sort-list",
        },
        {
          number: "150",
          name: "Evaluate Reverse Polish Notation",
          linke:
            "https://leetcode-cn.com/problems/evaluate-reverse-polish-notation",
        },
        {
          number: "151",
          name: "Reverse Words in a String",
          linke: "https://leetcode-cn.com/problems/reverse-words-in-a-string",
        },
        {
          number: "153",
          name: "Find Minimum in Rotated Sorted Array",
          linke:
            "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array",
        },
        {
          number: "154",
          name: "Find Minimum in Rotated Sorted Array II",
          linke:
            "https://leetcode-cn.com/problems/find-minimum-in-rotated-sorted-array-ii",
        },
        {
          number: "155",
          name: "Min Stack",
          linke: "https://leetcode-cn.com/problems/min-stack",
        },
        {
          number: "156",
          name: "Binary Tree Upside Down",
          linke: "https://leetcode-cn.com/problems/binary-tree-upside-down",
        },
        {
          number: "160",
          name: "Intersection of Two Linked Lists",
          linke:
            "https://leetcode-cn.com/problems/intersection-of-two-linked-lists",
        },
        {
          number: "161",
          name: "One Edit Distance",
          linke: "https://leetcode-cn.com/problems/one-edit-distance",
        },
        {
          number: "162",
          name: "Find Peak Element",
          linke: "https://leetcode-cn.com/problems/find-peak-element",
        },
        {
          number: "163",
          name: "Missing Ranges",
          linke: "https://leetcode-cn.com/problems/missing-ranges",
        },
        {
          number: "165",
          name: "Compare Version Numbers",
          linke: "https://leetcode-cn.com/problems/compare-version-numbers",
        },
        {
          number: "167",
          name: "Two Sum II - Input array is sorted",
          linke:
            "https://leetcode-cn.com/problems/two-sum-ii-input-array-is-sorted",
        },
        {
          number: "168",
          name: "Excel Sheet Column Title",
          linke: "https://leetcode-cn.com/problems/excel-sheet-column-title",
        },
        {
          number: "169",
          name: "Majority Element",
          linke: "https://leetcode-cn.com/problems/majority-element",
        },
        {
          number: "170",
          name: "Two Sum III - Data structure design",
          linke:
            "https://leetcode-cn.com/problems/two-sum-iii-data-structure-design",
        },
        {
          number: "171",
          name: "Excel Sheet Column Number",
          linke: "https://leetcode-cn.com/problems/excel-sheet-column-number",
        },
        {
          number: "173",
          name: "Binary Search Tree Iterator",
          linke: "https://leetcode-cn.com/problems/binary-search-tree-iterator",
        },
        {
          number: "186",
          name: "Reverse Words in a String II",
          linke:
            "https://leetcode-cn.com/problems/reverse-words-in-a-string-ii",
        },
        {
          number: "187",
          name: "Repeated DNA Sequences",
          linke: "https://leetcode-cn.com/problems/repeated-dna-sequences",
        },
        {
          number: "198",
          name: "House Robber",
          linke: "https://leetcode-cn.com/problems/house-robber",
        },
        {
          number: "199",
          name: "Binary Tree Right Side View",
          linke: "https://leetcode-cn.com/problems/binary-tree-right-side-view",
        },
        {
          number: "200",
          name: "Number of Islands",
          linke: "https://leetcode-cn.com/problems/number-of-islands",
        },
        {
          number: "202",
          name: "Happy Number",
          linke: "https://leetcode-cn.com/problems/happy-number",
        },
        {
          number: "203",
          name: "Remove Linked List Elements",
          linke: "https://leetcode-cn.com/problems/remove-linked-list-elements",
        },
        {
          number: "205",
          name: "Isomorphic Strings",
          linke: "https://leetcode-cn.com/problems/isomorphic-strings",
        },
        {
          number: "206",
          name: "Reverse Linked List",
          linke: "https://leetcode-cn.com/problems/reverse-linked-list",
        },
        {
          number: "209",
          name: "Minimum Size Subarray Sum",
          linke: "https://leetcode-cn.com/problems/minimum-size-subarray-sum",
        },
        {
          number: "213",
          name: "House Robber II",
          linke: "https://leetcode-cn.com/problems/house-robber-ii",
        },
        {
          number: "214",
          name: "Shortest Palindrome",
          linke: "https://leetcode-cn.com/problems/shortest-palindrome",
        },
        {
          number: "215",
          name: "Kth Largest Element in an Array",
          linke:
            "https://leetcode-cn.com/problems/kth-largest-element-in-an-array",
        },
        {
          number: "216",
          name: "Combination Sum III",
          linke: "https://leetcode-cn.com/problems/combination-sum-iii",
        },
        {
          number: "217",
          name: "Contains Duplicate",
          linke: "https://leetcode-cn.com/problems/contains-duplicate",
        },
        {
          number: "219",
          name: "Contains Duplicate II",
          linke: "https://leetcode-cn.com/problems/contains-duplicate-ii",
        },
        {
          number: "220",
          name: "Contains Duplicate III",
          linke: "https://leetcode-cn.com/problems/contains-duplicate-iii",
        },
        {
          number: "221",
          name: "Maximal Square",
          linke: "https://leetcode-cn.com/problems/maximal-square",
        },
        {
          number: "224",
          name: "Basic Calculator",
          linke: "https://leetcode-cn.com/problems/basic-calculator",
        },
        {
          number: "225",
          name: "Implement Stack using Queues",
          linke:
            "https://leetcode-cn.com/problems/implement-stack-using-queues",
        },
        {
          number: "226",
          name: "Invert Binary Tree",
          linke: "https://leetcode-cn.com/problems/invert-binary-tree",
        },
        {
          number: "227",
          name: "Basic Calculator II",
          linke: "https://leetcode-cn.com/problems/basic-calculator-ii",
        },
        {
          number: "228",
          name: "Summary Ranges",
          linke: "https://leetcode-cn.com/problems/summary-ranges",
        },
        {
          number: "229",
          name: "Majority Element II",
          linke: "https://leetcode-cn.com/problems/majority-element-ii",
        },
        {
          number: "230",
          name: "Kth Smallest Element in a BST",
          linke:
            "https://leetcode-cn.com/problems/kth-smallest-element-in-a-bst",
        },
        {
          number: "232",
          name: "Implement Queue using Stacks",
          linke:
            "https://leetcode-cn.com/problems/implement-queue-using-stacks",
        },
        {
          number: "234",
          name: "Palindrome Linked List",
          linke: "https://leetcode-cn.com/problems/palindrome-linked-list",
        },
        {
          number: "235",
          name: "Lowest Common Ancestor of a Binary Search Tree",
          linke:
            "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-search-tree",
        },
        {
          number: "236",
          name: "Lowest Common Ancestor of a Binary Tree",
          linke:
            "https://leetcode-cn.com/problems/lowest-common-ancestor-of-a-binary-tree",
        },
        {
          number: "237",
          name: "Delete Node in a Linked List",
          linke:
            "https://leetcode-cn.com/problems/delete-node-in-a-linked-list",
        },
        {
          number: "238",
          name: "Product of Array Except Self",
          linke:
            "https://leetcode-cn.com/problems/product-of-array-except-self",
        },
        {
          number: "239",
          name: "Sliding Window Maximum",
          linke: "https://leetcode-cn.com/problems/sliding-window-maximum",
        },
        {
          number: "240",
          name: "Search a 2D Matrix II",
          linke: "https://leetcode-cn.com/problems/search-a-2d-matrix-ii",
        },
        {
          number: "242",
          name: "Valid Anagram",
          linke: "https://leetcode-cn.com/problems/valid-anagram",
        },
        {
          number: "243",
          name: "Shortest Word Distance",
          linke: "https://leetcode-cn.com/problems/shortest-word-distance",
        },
        {
          number: "244",
          name: "Shortest Word Distance II",
          linke: "https://leetcode-cn.com/problems/shortest-word-distance-ii",
        },
        {
          number: "245",
          name: "Shortest Word Distance III",
          linke: "https://leetcode-cn.com/problems/shortest-word-distance-iii",
        },
        {
          number: "246",
          name: "Strobogrammatic Number",
          linke: "https://leetcode-cn.com/problems/strobogrammatic-number",
        },
        {
          number: "247",
          name: "Strobogrammatic Number II",
          linke: "https://leetcode-cn.com/problems/strobogrammatic-number-ii",
        },
        {
          number: "250",
          name: "Count Univalue Subtrees",
          linke: "https://leetcode-cn.com/problems/count-univalue-subtrees",
        },
        {
          number: "251",
          name: "Flatten 2D Vector",
          linke: "https://leetcode-cn.com/problems/flatten-2d-vector",
        },
        {
          number: "252",
          name: "Meeting Rooms",
          linke: "https://leetcode-cn.com/problems/meeting-rooms",
        },
        {
          number: "253",
          name: "Meeting Rooms II",
          linke: "https://leetcode-cn.com/problems/meeting-rooms-ii",
        },
        {
          number: "254",
          name: "Factor Combinations",
          linke: "https://leetcode-cn.com/problems/factor-combinations",
        },
        {
          number: "255",
          name: "Verify Preorder Sequence in Binary Search Tree",
          linke:
            "https://leetcode-cn.com/problems/verify-preorder-sequence-in-binary-search-tree",
        },
        {
          number: "256",
          name: "Paint House",
          linke: "https://leetcode-cn.com/problems/paint-house",
        },
        {
          number: "257",
          name: "Binary Tree Paths",
          linke: "https://leetcode-cn.com/problems/binary-tree-paths",
        },
        {
          number: "258",
          name: "Add Digits",
          linke: "https://leetcode-cn.com/problems/add-digits",
        },
        {
          number: "259",
          name: "3Sum Smaller",
          linke: "https://leetcode-cn.com/problems/3sum-smaller",
        },
        {
          number: "263",
          name: "Ugly Number",
          linke: "https://leetcode-cn.com/problems/ugly-number",
        },
        {
          number: "264",
          name: "Ugly Number II",
          linke: "https://leetcode-cn.com/problems/ugly-number-ii",
        },
        {
          number: "266",
          name: "Palindrome Permutation",
          linke: "https://leetcode-cn.com/problems/palindrome-permutation",
        },
        {
          number: "267",
          name: "Palindrome Permutation II",
          linke: "https://leetcode-cn.com/problems/palindrome-permutation-ii",
        },
        {
          number: "268",
          name: "Missing Number",
          linke: "https://leetcode-cn.com/problems/missing-number",
        },
        {
          number: "270",
          name: "Closest Binary Search Tree Value",
          linke:
            "https://leetcode-cn.com/problems/closest-binary-search-tree-value",
        },
        {
          number: "274",
          name: "H-Index",
          linke: "https://leetcode-cn.com/problems/h-index",
        },
        {
          number: "275",
          name: "H-Index II",
          linke: "https://leetcode-cn.com/problems/h-index-ii",
        },
        {
          number: "277",
          name: "Find the Celebrity",
          linke: "https://leetcode-cn.com/problems/find-the-celebrity",
        },
        {
          number: "278",
          name: "First Bad Version",
          linke: "https://leetcode-cn.com/problems/first-bad-version",
        },
        {
          number: "279",
          name: "Perfect Squares",
          linke: "https://leetcode-cn.com/problems/perfect-squares",
        },
        {
          number: "280",
          name: "Wiggle Sort",
          linke: "https://leetcode-cn.com/problems/wiggle-sort",
        },
        {
          number: "283",
          name: "Move Zeroes",
          linke: "https://leetcode-cn.com/problems/move-zeroes",
        },
        {
          number: "285",
          name: "Inorder Successor in BST",
          linke: "https://leetcode-cn.com/problems/inorder-successor-in-bst",
        },
        {
          number: "286",
          name: "Walls and Gates",
          linke: "https://leetcode-cn.com/problems/walls-and-gates",
        },
        {
          number: "287",
          name: "Find the Duplicate Number",
          linke: "https://leetcode-cn.com/problems/find-the-duplicate-number",
        },
        {
          number: "288",
          name: "Unique Word Abbreviation",
          linke: "https://leetcode-cn.com/problems/unique-word-abbreviation",
        },
        {
          number: "290",
          name: "Word Pattern",
          linke: "https://leetcode-cn.com/problems/word-pattern",
        },
        {
          number: "295",
          name: "Find Median from Data Stream",
          linke:
            "https://leetcode-cn.com/problems/find-median-from-data-stream",
        },
        {
          number: "296",
          name: "Best Meeting Point",
          linke: "https://leetcode-cn.com/problems/best-meeting-point",
        },
        {
          number: "298",
          name: "Binary Tree Longest Consecutive Sequence",
          linke:
            "https://leetcode-cn.com/problems/binary-tree-longest-consecutive-sequence",
        },
        {
          number: "299",
          name: "Bulls and Cows",
          linke: "https://leetcode-cn.com/problems/bulls-and-cows",
        },
        {
          number: "300",
          name: "Longest Increasing Subsequence",
          linke:
            "https://leetcode-cn.com/problems/longest-increasing-subsequence",
        },
        {
          number: "303",
          name: "Range Sum Query - Immutable",
          linke: "https://leetcode-cn.com/problems/range-sum-query-immutable",
        },
        {
          number: "304",
          name: "Range Sum Query 2D - Immutable",
          linke:
            "https://leetcode-cn.com/problems/range-sum-query-2d-immutable",
        },
        {
          number: "305",
          name: "Number of Islands II",
          linke: "https://leetcode-cn.com/problems/number-of-islands-ii",
        },
        {
          number: "311",
          name: "Sparse Matrix Multiplication",
          linke:
            "https://leetcode-cn.com/problems/sparse-matrix-multiplication",
        },
        {
          number: "313",
          name: "Super Ugly Number",
          linke: "https://leetcode-cn.com/problems/super-ugly-number",
        },
        {
          number: "316",
          name: "Remove Duplicate Letters",
          linke: "https://leetcode-cn.com/problems/remove-duplicate-letters",
        },
        {
          number: "320",
          name: "Generalized Abbreviation",
          linke: "https://leetcode-cn.com/problems/generalized-abbreviation",
        },
        {
          number: "322",
          name: "Coin Change",
          linke: "https://leetcode-cn.com/problems/coin-change",
        },
        {
          number: "325",
          name: "Maximum Size Subarray Sum Equals k",
          linke:
            "https://leetcode-cn.com/problems/maximum-size-subarray-sum-equals-k",
        },
        {
          number: "326",
          name: "Power of Three",
          linke: "https://leetcode-cn.com/problems/power-of-three",
        },
        {
          number: "328",
          name: "Odd Even Linked List",
          linke: "https://leetcode-cn.com/problems/odd-even-linked-list",
        },
        {
          number: "330",
          name: "Patching Array",
          linke: "https://leetcode-cn.com/problems/patching-array",
        },
        {
          number: "334",
          name: "Increasing Triplet Subsequence",
          linke:
            "https://leetcode-cn.com/problems/increasing-triplet-subsequence",
        },
        {
          number: "337",
          name: "House Robber III",
          linke: "https://leetcode-cn.com/problems/house-robber-iii",
        },
        {
          number: "339",
          name: "Nested List Weight Sum",
          linke: "https://leetcode-cn.com/problems/nested-list-weight-sum",
        },
        {
          number: "340",
          name: "Longest Substring with At Most K Distinct Characters",
          linke:
            "https://leetcode-cn.com/problems/longest-substring-with-at-most-k-distinct-characters",
        },
        {
          number: "341",
          name: "Flatten Nested List Iterator",
          linke:
            "https://leetcode-cn.com/problems/flatten-nested-list-iterator",
        },
        {
          number: "342",
          name: "Power of Four",
          linke: "https://leetcode-cn.com/problems/power-of-four",
        },
        {
          number: "344",
          name: "Reverse String",
          linke: "https://leetcode-cn.com/problems/reverse-string",
        },
        {
          number: "345",
          name: "Reverse Vowels of a String",
          linke: "https://leetcode-cn.com/problems/reverse-vowels-of-a-string",
        },
        {
          number: "346",
          name: "Moving Average from Data Stream",
          linke:
            "https://leetcode-cn.com/problems/moving-average-from-data-stream",
        },
        {
          number: "347",
          name: "Top K Frequent Elements",
          linke: "https://leetcode-cn.com/problems/top-k-frequent-elements",
        },
        {
          number: "349",
          name: "Intersection of Two Arrays",
          linke: "https://leetcode-cn.com/problems/intersection-of-two-arrays",
        },
        {
          number: "350",
          name: "Intersection of Two Arrays II",
          linke:
            "https://leetcode-cn.com/problems/intersection-of-two-arrays-ii",
        },
        {
          number: "356",
          name: "Line Reflection",
          linke: "https://leetcode-cn.com/problems/line-reflection",
        },
        {
          number: "359",
          name: "Logger Rate Limiter",
          linke: "https://leetcode-cn.com/problems/logger-rate-limiter",
        },
        {
          number: "360",
          name: "Sort Transformed Array",
          linke: "https://leetcode-cn.com/problems/sort-transformed-array",
        },
        {
          number: "362",
          name: "Design Hit Counter",
          linke: "https://leetcode-cn.com/problems/design-hit-counter",
        },
        {
          number: "365",
          name: "Water and Jug Problem",
          linke: "https://leetcode-cn.com/problems/water-and-jug-problem",
        },
        {
          number: "366",
          name: "Find Leaves of Binary Tree",
          linke: "https://leetcode-cn.com/problems/find-leaves-of-binary-tree",
        },
        {
          number: "367",
          name: "Valid Perfect Square",
          linke: "https://leetcode-cn.com/problems/valid-perfect-square",
        },
        {
          number: "369",
          name: "Plus One Linked List",
          linke: "https://leetcode-cn.com/problems/plus-one-linked-list",
        },
        {
          number: "370",
          name: "Range Addition",
          linke: "https://leetcode-cn.com/problems/range-addition",
        },
        {
          number: "373",
          name: "Find K Pairs with Smallest Sums",
          linke:
            "https://leetcode-cn.com/problems/find-k-pairs-with-smallest-sums",
        },
        {
          number: "374",
          name: "Guess Number Higher or Lower",
          linke:
            "https://leetcode-cn.com/problems/guess-number-higher-or-lower",
        },
        {
          number: "376",
          name: "Wiggle Subsequence",
          linke: "https://leetcode-cn.com/problems/wiggle-subsequence",
        },
        {
          number: "377",
          name: "Combination Sum IV",
          linke: "https://leetcode-cn.com/problems/combination-sum-iv",
        },
        {
          number: "378",
          name: "Kth Smallest Element in a Sorted Matrix",
          linke:
            "https://leetcode-cn.com/problems/kth-smallest-element-in-a-sorted-matrix",
        },
        {
          number: "379",
          name: "Design Phone Directory",
          linke: "https://leetcode-cn.com/problems/design-phone-directory",
        },
        {
          number: "380",
          name: "Insert Delete GetRandom O(1)",
          linke: "https://leetcode-cn.com/problems/insert-delete-getrandom-o1",
        },
        {
          number: "381",
          name: "Insert Delete GetRandom O(1) - Duplicates allowed",
          linke:
            "https://leetcode-cn.com/problems/insert-delete-getrandom-o1-duplicates-allowed",
        },
        {
          number: "383",
          name: "Ransom Note",
          linke: "https://leetcode-cn.com/problems/ransom-note",
        },
        {
          number: "387",
          name: "First Unique Character in a String",
          linke:
            "https://leetcode-cn.com/problems/first-unique-character-in-a-string",
        },
        {
          number: "388",
          name: "Longest Absolute File Path",
          linke: "https://leetcode-cn.com/problems/longest-absolute-file-path",
        },
        {
          number: "389",
          name: "Find the Difference",
          linke: "https://leetcode-cn.com/problems/find-the-difference",
        },
        {
          number: "392",
          name: "Is Subsequence",
          linke: "https://leetcode-cn.com/problems/is-subsequence",
        },
        {
          number: "400",
          name: "Nth Digit",
          linke: "https://leetcode-cn.com/problems/nth-digit",
        },
      ],
    };
  },
  methods: {
    tabChange(index) {
      this.TabActive = index;
    },
    first() {
      this.firstOpen = !this.firstOpen;
    },
    second() {
      this.secondOpen = !this.secondOpen;
    },
    third() {
      this.thirdOpen = !this.thirdOpen;
    },
    fourth() {
      this.fourthOpen = !this.fourthOpen;
    },
  },
};
</script>

<style lang="css" scoped>
.seqlist-ban img {
  height: 320px !important;
}
</style>
